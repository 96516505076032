import StateListenerRegistry from '../base/redux/StateListenerRegistry';
import { isAudioMuteButtonDisabled } from './functions.any';
/**
 * Notifies when audio availability changes.
 */
StateListenerRegistry.register(
/* selector */ (state) => isAudioMuteButtonDisabled(state), 
/* listener */ (disabled, store, previousDisabled) => {
    if (typeof APP !== 'object') {
        return;
    }
    if (disabled !== previousDisabled) {
        APP.API.notifyAudioAvailabilityChanged(!disabled);
    }
});
