/**
 * The interface config keys to whitelist, the keys that can be overridden.
 *
 * @private
 * @type Array
 */
export default [
    'APP_NAME',
    'WEB_URL',
    'LARAVEL_MEET_URL',
    'AUDIO_LEVEL_PRIMARY_COLOR',
    'AUDIO_LEVEL_SECONDARY_COLOR',
    'AUTO_PIN_LATEST_SCREEN_SHARE',
    'CLOSE_PAGE_GUEST_HINT',
    'CONNECTION_INDICATOR_AUTO_HIDE_ENABLED',
    'CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT',
    'CONNECTION_INDICATOR_DISABLED',
    'DEFAULT_BACKGROUND',
    'DISABLE_PRESENCE_STATUS',
    'DISABLE_JOIN_LEAVE_NOTIFICATIONS',
    'DEFAULT_LOCAL_DISPLAY_NAME',
    'DEFAULT_REMOTE_DISPLAY_NAME',
    'DISABLE_DOMINANT_SPEAKER_INDICATOR',
    'DISABLE_FOCUS_INDICATOR',
    'DISABLE_PRIVATE_MESSAGES',
    'DISABLE_RINGING',
    'DISABLE_TRANSCRIPTION_SUBTITLES',
    'DISABLE_VIDEO_BACKGROUND',
    'DISPLAY_WELCOME_PAGE_CONTENT',
    'ENABLE_DIAL_OUT',
    'ENABLE_FEEDBACK_ANIMATION',
    'FILM_STRIP_MAX_HEIGHT',
    'GENERATE_ROOMNAMES_ON_WELCOME_PAGE',
    'HIDE_INVITE_MORE_HEADER',
    'INDICATOR_FONT_SIZES',
    'INITIAL_TOOLBAR_TIMEOUT',
    'LANG_DETECTION',
    'LIVE_STREAMING_HELP_LINK',
    'LOCAL_THUMBNAIL_RATIO',
    'MAXIMUM_ZOOMING_COEFFICIENT',
    'NATIVE_APP_NAME',
    'OPTIMAL_BROWSERS',
    'PHONE_NUMBER_REGEX',
    'PROVIDER_NAME',
    'RECENT_LIST_ENABLED',
    'REMOTE_THUMBNAIL_RATIO',
    'SETTINGS_SECTIONS',
    'SHARING_FEATURES',
    'SHOW_CHROME_EXTENSION_BANNER',
    'SHOW_POWERED_BY',
    'SUPPORT_URL',
    'TILE_VIEW_MAX_COLUMNS',
    'TOOLBAR_ALWAYS_VISIBLE',
    'TOOLBAR_BUTTONS',
    'TOOLBAR_TIMEOUT',
    'UNSUPPORTED_BROWSERS',
    'VERTICAL_FILMSTRIP',
    'VIDEO_LAYOUT_FIT',
    'VIDEO_QUALITY_LABEL_DISABLED'
];
