/* eslint-disable lines-around-comment */
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { batch, connect } from 'react-redux';
import { isMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n/functions';
import { IconDotsHorizontal } from '../../../base/icons/svg';
import { getLocalParticipant } from '../../../base/participants/functions';
import Popover from '../../../base/popover/components/Popover.web';
import { setParticipantContextMenuOpen } from '../../../base/responsive-ui/actions';
import { getHideSelfView } from '../../../base/settings/functions.web';
import { getLocalVideoTrack } from '../../../base/tracks/functions';
import Button from '../../../base/ui/components/web/Button';
import ContextMenu from '../../../base/ui/components/web/ContextMenu';
import ContextMenuItemGroup from '../../../base/ui/components/web/ContextMenuItemGroup';
// @ts-ignore
import ConnectionIndicatorContent from '../../../connection-indicator/components/web/ConnectionIndicatorContent';
import { THUMBNAIL_TYPE } from '../../../filmstrip/constants';
import { isStageFilmstripAvailable } from '../../../filmstrip/functions.web';
import { renderConnectionStatus } from '../../actions.web';
// @ts-ignore
import ConnectionStatusButton from './ConnectionStatusButton';
// @ts-ignore
import FlipLocalVideoButton from './FlipLocalVideoButton';
// @ts-ignore
import HideSelfViewVideoButton from './HideSelfViewVideoButton';
// @ts-ignore
import TogglePinToStageButton from './TogglePinToStageButton';
const styles = () => {
    return {
        triggerButton: {
            padding: '3px !important',
            borderRadius: '4px',
            '& svg': {
                width: '18px',
                height: '18px'
            }
        },
        contextMenu: {
            position: 'relative',
            marginTop: 0,
            right: 'auto',
            padding: '0',
            minWidth: '200px'
        },
        flipText: {
            marginLeft: '36px'
        }
    };
};
/**
 * React Component for displaying an icon associated with opening the
 * the video menu for the local participant.
 *
 * @augments {Component}
 */
class LocalVideoMenuTriggerButton extends Component {
    /**
     * Initializes a new LocalVideoMenuTriggerButton instance.
     *
     * @param {Object} props - The read-only React Component props with which
     * the new instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this._onPopoverClose = this._onPopoverClose.bind(this);
        this._onPopoverOpen = this._onPopoverOpen.bind(this);
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { _localParticipantId, _menuPosition, _overflowDrawer, _showConnectionInfo, _showHideSelfViewButton, _showLocalVideoFlipButton, _showPinToStage, buttonVisible, classes, hidePopover, popoverVisible, t } = this.props;
        const content = _showConnectionInfo
            ? React.createElement(ConnectionIndicatorContent, { participantId: _localParticipantId })
            : (React.createElement(ContextMenu, { className: classes.contextMenu, hidden: false, inDrawer: _overflowDrawer },
                React.createElement(ContextMenuItemGroup, null,
                    _showLocalVideoFlipButton
                        && React.createElement(FlipLocalVideoButton, { className: _overflowDrawer ? classes.flipText : '', onClick: hidePopover }),
                    _showHideSelfViewButton
                        && React.createElement(HideSelfViewVideoButton, { className: _overflowDrawer ? classes.flipText : '', onClick: hidePopover }),
                    _showPinToStage && React.createElement(TogglePinToStageButton, { className: _overflowDrawer ? classes.flipText : '', noIcon: true, onClick: hidePopover, participantID: _localParticipantId }),
                    isMobileBrowser()
                        && React.createElement(ConnectionStatusButton, { participantId: _localParticipantId }))));
        return (isMobileBrowser() || _showLocalVideoFlipButton || _showHideSelfViewButton
            ? React.createElement(Popover, { content: content, id: 'local-video-menu-trigger', onPopoverClose: this._onPopoverClose, onPopoverOpen: this._onPopoverOpen, overflowDrawer: _overflowDrawer, position: _menuPosition, visible: popoverVisible }, buttonVisible && !isMobileBrowser() && (React.createElement(Button, { accessibilityLabel: t('dialog.localUserControls'), className: classes.triggerButton, icon: IconDotsHorizontal, size: 'small' })))
            : null);
    }
    /**
     * Disable and hide toolbox while context menu is open.
     *
     * @returns {void}
     */
    _onPopoverOpen() {
        const { dispatch, showPopover } = this.props;
        showPopover();
        dispatch(setParticipantContextMenuOpen(true));
    }
    /**
     * Render normal context menu next time popover dialog opens.
     *
     * @returns {void}
     */
    _onPopoverClose() {
        const { hidePopover, dispatch } = this.props;
        hidePopover();
        batch(() => {
            dispatch(setParticipantContextMenuOpen(false));
            dispatch(renderConnectionStatus(false));
        });
    }
}
/**
 * Maps (parts of) the Redux state to the associated {@code LocalVideoMenuTriggerButton}'s props.
 *
 * @param {Object} state - The Redux state.
 * @param {Object} ownProps - The own props of the component.
 * @private
 * @returns {IProps}
 */
function _mapStateToProps(state, ownProps) {
    const { thumbnailType } = ownProps;
    const localParticipant = getLocalParticipant(state);
    const { disableLocalVideoFlip, disableSelfViewSettings } = state['features/base/config'];
    const videoTrack = getLocalVideoTrack(state['features/base/tracks']);
    const { overflowDrawer } = state['features/toolbox'];
    const { showConnectionInfo } = state['features/base/connection'];
    const showHideSelfViewButton = !disableSelfViewSettings && !getHideSelfView(state);
    let _menuPosition;
    switch (thumbnailType) {
        case THUMBNAIL_TYPE.TILE:
            _menuPosition = 'left-start';
            break;
        case THUMBNAIL_TYPE.VERTICAL:
            _menuPosition = 'left-start';
            break;
        case THUMBNAIL_TYPE.HORIZONTAL:
            _menuPosition = 'top-start';
            break;
        default:
            _menuPosition = 'auto';
    }
    return {
        _menuPosition,
        _showLocalVideoFlipButton: !disableLocalVideoFlip && videoTrack?.videoType !== 'desktop',
        _showHideSelfViewButton: showHideSelfViewButton,
        _overflowDrawer: overflowDrawer,
        _localParticipantId: localParticipant?.id ?? '',
        _showConnectionInfo: Boolean(showConnectionInfo),
        _showPinToStage: isStageFilmstripAvailable(state)
    };
}
export default translate(connect(_mapStateToProps)(withStyles(styles)(LocalVideoMenuTriggerButton)));
