import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
// @ts-ignore
import { Avatar } from '../../../../../base/avatar';
import { isLocalParticipantModerator } from '../../../../../base/participants/functions';
import ContextMenu from '../../../../../base/ui/components/web/ContextMenu';
import ContextMenuItemGroup from '../../../../../base/ui/components/web/ContextMenuItemGroup';
import { getBreakoutRooms } from '../../../../../breakout-rooms/functions';
import { showOverflowDrawer } from '../../../../../toolbox/functions.web';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import SendToRoomButton from '../../../../../video-menu/components/web/SendToRoomButton';
import { AVATAR_SIZE } from '../../../../constants';
const useStyles = makeStyles()(theme => {
    return {
        text: {
            color: theme.palette.text02,
            padding: '10px 16px',
            height: '40px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box'
        }
    };
});
export const RoomParticipantContextMenu = ({ entity, offsetTarget, onEnter, onLeave, onSelect }) => {
    const { classes: styles } = useStyles();
    const { t } = useTranslation();
    const isLocalModerator = useSelector(isLocalParticipantModerator);
    const lowerMenu = useCallback(() => onSelect(true), [onSelect]);
    const rooms = useSelector(getBreakoutRooms);
    const overflowDrawer = useSelector(showOverflowDrawer);
    const breakoutRoomsButtons = useMemo(() => Object.values(rooms || {}).map((room) => {
        if (room.id !== entity?.room?.id) {
            return (React.createElement(SendToRoomButton, { key: room.id, onClick: lowerMenu, participantID: entity?.jid, room: room }));
        }
        return null;
    })
        .filter(Boolean), [entity, rooms]);
    return isLocalModerator && (React.createElement(ContextMenu, { entity: entity, isDrawerOpen: Boolean(entity), offsetTarget: offsetTarget, onClick: lowerMenu, onDrawerClose: onSelect, onMouseEnter: onEnter, onMouseLeave: onLeave },
        overflowDrawer && entity?.jid && React.createElement(ContextMenuItemGroup, { actions: [{
                    accessibilityLabel: entity?.participantName,
                    customIcon: React.createElement(Avatar, { displayName: entity?.participantName, size: AVATAR_SIZE }),
                    text: entity?.participantName
                }] }),
        React.createElement(ContextMenuItemGroup, null,
            React.createElement("div", { className: styles.text }, t('breakoutRooms.actions.sendToBreakoutRoom')),
            breakoutRoomsButtons)));
};
