import React, { PureComponent } from 'react';
import { getSecurityUiConfig } from '../../../base/config/functions.any';
import { translate } from '../../../base/i18n/functions';
import { isLocalParticipantModerator } from '../../../base/participants/functions';
import { connect } from '../../../base/redux/functions';
import Switch from '../../../base/ui/components/web/Switch';
import { isInBreakoutRoom } from '../../../breakout-rooms/functions';
import { toggleLobbyMode } from '../../actions';
/**
 * Implements a security feature section to control lobby mode.
 */
class LobbySection extends PureComponent {
    /**
     * Instantiates a new component.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this.state = {
            lobbyEnabled: props._lobbyEnabled
        };
        this._onToggleLobby = this._onToggleLobby.bind(this);
    }
    /**
     * Implements React's {@link Component#getDerivedStateFromProps()}.
     *
     * @inheritdoc
     */
    static getDerivedStateFromProps(props, state) {
        if (props._lobbyEnabled !== state.lobbyEnabled) {
            return {
                lobbyEnabled: props._lobbyEnabled
            };
        }
        return null;
    }
    /**
     * Implements {@code PureComponent#render}.
     *
     * @inheritdoc
     */
    render() {
        const { _visible, t } = this.props;
        if (!_visible) {
            return null;
        }
        return (React.createElement("div", { id: 'lobby-section' },
            React.createElement("p", { className: 'description', role: 'banner' }, t('lobby.enableDialogText')),
            React.createElement("div", { className: 'control-row' },
                React.createElement("label", { htmlFor: 'lobby-section-switch' }, t('lobby.toggleLabel')),
                React.createElement(Switch, { checked: this.state.lobbyEnabled, id: 'lobby-section-switch', onChange: this._onToggleLobby }))));
    }
    /**
     * Callback to be invoked when the user toggles the lobby feature on or off.
     *
     * @returns {void}
     */
    _onToggleLobby() {
        const newValue = !this.state.lobbyEnabled;
        this.setState({
            lobbyEnabled: newValue
        });
        this.props.dispatch(toggleLobbyMode(newValue));
        window.parent.postMessage({
            event_id: 'lobbyChange',
            type: newValue
        }, '*');
    }
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
function mapStateToProps(state) {
    const { conference } = state['features/base/conference'];
    const { hideLobbyButton } = getSecurityUiConfig(state);
    return {
        _lobbyEnabled: state['features/lobby'].lobbyEnabled,
        _visible: conference?.isLobbySupported() && isLocalParticipantModerator(state)
            && !hideLobbyButton && !isInBreakoutRoom(state)
    };
}
export default translate(connect(mapStateToProps)(LobbySection));
