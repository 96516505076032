import React from 'react';
// @ts-ignore
import { AbstractDialogTab } from '../../../base/dialog';
import { translate } from '../../../base/i18n/functions';
import Checkbox from '../../../base/ui/components/web/Checkbox';
/**
 * React {@code Component} for modifying language and moderator settings.
 *
 * @augments Component
 */
class ModeratorTab extends AbstractDialogTab {
    /**
     * Initializes a new {@code ModeratorTab} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        // Bind event handler so it is only bound once for every instance.
        this._onStartAudioMutedChanged = this._onStartAudioMutedChanged.bind(this);
        this._onStartVideoMutedChanged = this._onStartVideoMutedChanged.bind(this);
        this._onStartReactionsMutedChanged = this._onStartReactionsMutedChanged.bind(this);
        this._onFollowMeEnabledChanged = this._onFollowMeEnabledChanged.bind(this);
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        return React.createElement("div", { className: 'moderator-tab box' }, this._renderModeratorSettings());
    }
    /**
     * Callback invoked to select if conferences should start
     * with audio muted.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onStartAudioMutedChanged({ target: { checked } }) {
        super._onChange({ startAudioMuted: checked });
    }
    /**
     * Callback invoked to select if conferences should start
     * with video disabled.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onStartVideoMutedChanged({ target: { checked } }) {
        super._onChange({ startVideoMuted: checked });
    }
    /**
     * Callback invoked to select if conferences should start
     * with reactions muted.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onStartReactionsMutedChanged({ target: { checked } }) {
        super._onChange({ startReactionsMuted: checked });
    }
    /**
     * Callback invoked to select if follow-me mode
     * should be activated.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onFollowMeEnabledChanged({ target: { checked } }) {
        super._onChange({ followMeEnabled: checked });
    }
    /**
     * Returns the React Element for modifying conference-wide settings.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderModeratorSettings() {
        const { disableReactionsModeration, followMeActive, followMeEnabled, startAudioMuted, startVideoMuted, startReactionsMuted, t // @ts-ignore
         } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'moderator' },
            React.createElement("div", { className: 'moderator-settings-wrapper' },
                React.createElement(Checkbox, { checked: startAudioMuted, className: 'settings-checkbox', label: t('settings.startAudioMuted'), name: 'start-audio-muted', onChange: this._onStartAudioMutedChanged }),
                React.createElement(Checkbox, { checked: startVideoMuted, className: 'settings-checkbox', label: t('settings.startVideoMuted'), name: 'start-video-muted', onChange: this._onStartVideoMutedChanged }),
                React.createElement(Checkbox, { checked: followMeEnabled && !followMeActive, className: 'settings-checkbox', disabled: followMeActive, label: t('settings.followMe'), name: 'follow-me', onChange: this._onFollowMeEnabledChanged }),
                !disableReactionsModeration
                    && React.createElement(Checkbox, { checked: startReactionsMuted, className: 'settings-checkbox', label: t('settings.startReactionsMuted'), name: 'start-reactions-muted', onChange: this._onStartReactionsMutedChanged }))));
    }
}
// @ts-ignore
export default translate(ModeratorTab);
