import React from 'react';
// @ts-expect-error
import keyboardShortcut from '../../../../../modules/keyboardshortcut/keyboardshortcut';
import AbstractDialogTab from '../../../base/dialog/components/web/AbstractDialogTab';
import { translate } from '../../../base/i18n/functions';
import Checkbox from '../../../base/ui/components/web/Checkbox';
import Select from '../../../base/ui/components/web/Select';
import { MAX_ACTIVE_PARTICIPANTS } from '../../../filmstrip/constants';
import { SS_DEFAULT_FRAME_RATE } from '../../constants';
/**
 * React {@code Component} for modifying language and moderator settings.
 *
 * @augments Component
 */
class MoreTab extends AbstractDialogTab {
    /**
     * Initializes a new {@code MoreTab} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        // Bind event handler so it is only bound once for every instance.
        this._onFramerateItemSelect = this._onFramerateItemSelect.bind(this);
        this._onLanguageItemSelect = this._onLanguageItemSelect.bind(this);
        this._onEnabledNotificationsChanged = this._onEnabledNotificationsChanged.bind(this);
        this._onShowPrejoinPageChanged = this._onShowPrejoinPageChanged.bind(this);
        this._onKeyboardShortcutEnableChanged = this._onKeyboardShortcutEnableChanged.bind(this);
        this._onHideSelfViewChanged = this._onHideSelfViewChanged.bind(this);
        this._renderMaxStageParticipantsSelect = this._renderMaxStageParticipantsSelect.bind(this);
        this._onMaxStageParticipantsSelect = this._onMaxStageParticipantsSelect.bind(this);
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const content = [];
        content.push(this._renderSettingsLeft());
        content.push(this._renderSettingsRight());
        return (React.createElement("div", { className: 'more-tab box', key: 'more' }, content));
    }
    /**
     * Callback invoked to select a frame rate from the select dropdown.
     *
     * @param {Object} e - The key event to handle.
     * @private
     * @returns {void}
     */
    _onFramerateItemSelect(e) {
        const frameRate = e.target.value;
        super._onChange({ currentFramerate: frameRate });
    }
    /**
     * Callback invoked to select a language from select dropdown.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onLanguageItemSelect(e) {
        const language = e.target.value;
        super._onChange({ currentLanguage: language });
    }
    /**
     * Callback invoked to select if the lobby
     * should be shown.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onShowPrejoinPageChanged({ target: { checked } }) {
        super._onChange({ showPrejoinPage: checked });
    }
    /**
     * Callback invoked to select if the given type of
     * notifications should be shown.
     *
     * @param {Object} e - The key event to handle.
     * @param {string} type - The type of the notification.
     *
     * @returns {void}
     */
    _onEnabledNotificationsChanged({ target: { checked } }, type) {
        super._onChange({
            enabledNotifications: {
                ...this.props.enabledNotifications,
                [type]: checked
            }
        });
    }
    /**
     * Callback invoked to select if global keyboard shortcuts
     * should be enabled.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onKeyboardShortcutEnableChanged({ target: { checked } }) {
        keyboardShortcut.enable(checked);
        super._onChange({ keyboardShortcutEnable: checked });
    }
    /**
     * Callback invoked to select if hide self view should be enabled.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onHideSelfViewChanged({ target: { checked } }) {
        super._onChange({ hideSelfView: checked });
    }
    /**
     * Callback invoked to select a max number of stage participants from the select dropdown.
     *
     * @param {Object} e - The key event to handle.
     * @private
     * @returns {void}
     */
    _onMaxStageParticipantsSelect(e) {
        const maxParticipants = Number(e.target.value);
        super._onChange({ maxStageParticipants: maxParticipants });
    }
    /**
     * Returns the React Element for the desktop share frame rate dropdown.
     *
     * @returns {ReactElement}
     */
    _renderFramerateSelect() {
        const { currentFramerate, desktopShareFramerates, t } = this.props;
        const frameRateItems = desktopShareFramerates.map((frameRate) => {
            return {
                value: frameRate,
                label: `${frameRate} ${t('settings.framesPerSecond')}`
            };
        });
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'frameRate' },
            React.createElement("div", { className: 'dropdown-menu' },
                React.createElement(Select, { bottomLabel: parseInt(currentFramerate, 10) > SS_DEFAULT_FRAME_RATE
                        ? t('settings.desktopShareHighFpsWarning')
                        : t('settings.desktopShareWarning'), label: t('settings.desktopShareFramerate'), onChange: this._onFramerateItemSelect, options: frameRateItems, value: currentFramerate }))));
    }
    /**
     * Returns the React Element for keyboardShortcut settings.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderKeyboardShortcutCheckbox() {
        const { t } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'keyboard-shortcut' },
            React.createElement("span", { className: 'checkbox-label' }, t('keyboardShortcuts.keyboardShortcuts')),
            React.createElement(Checkbox, { checked: keyboardShortcut.getEnabled(), label: t('prejoin.keyboardShortcuts'), name: 'enable-keyboard-shortcuts', onChange: this._onKeyboardShortcutEnableChanged })));
    }
    /**
     * Returns the React Element for self view setting.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderSelfViewCheckbox() {
        const { hideSelfView, t } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'selfview' },
            React.createElement("span", { className: 'checkbox-label' }, t('settings.selfView')),
            React.createElement(Checkbox, { checked: hideSelfView, label: t('videothumbnail.hideSelfView'), name: 'hide-self-view', onChange: this._onHideSelfViewChanged })));
    }
    /**
     * Returns the menu item for changing displayed language.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderLanguageSelect() {
        const { currentLanguage, languages, t } = this.props;
        const languageItems = languages.map((language) => {
            return {
                value: language,
                label: t(`languages:${language}`)
            };
        });
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'language' },
            React.createElement("div", { className: 'dropdown-menu' },
                React.createElement(Select, { label: t('settings.language'), onChange: this._onLanguageItemSelect, options: languageItems, value: currentLanguage }))));
    }
    /**
     * Returns the React Element for modifying prejoin screen settings.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderPrejoinScreenSettings() {
        const { t, showPrejoinPage } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'prejoin-screen' },
            React.createElement("span", { className: 'checkbox-label' }, t('prejoin.premeeting')),
            React.createElement(Checkbox, { checked: showPrejoinPage, label: t('prejoin.showScreen'), name: 'show-prejoin-page', onChange: this._onShowPrejoinPageChanged })));
    }
    /**
     * Returns the React Element for modifying the enabled notifications settings.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderNotificationsSettings() {
        const { t, enabledNotifications } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'notifications' },
            React.createElement("span", { className: 'checkbox-label' }, t('notify.displayNotifications')),
            Object.keys(enabledNotifications).map(key => (React.createElement(Checkbox, { checked: Boolean(enabledNotifications[key]), key: key, label: t(key), name: `show-${key}`, 
                /* eslint-disable-next-line react/jsx-no-bind */
                onChange: e => this._onEnabledNotificationsChanged(e, key) })))));
    }
    /**
     * Returns the React Element for the max stage participants dropdown.
     *
     * @returns {ReactElement}
     */
    _renderMaxStageParticipantsSelect() {
        const { maxStageParticipants, t, stageFilmstripEnabled } = this.props;
        if (!stageFilmstripEnabled) {
            return null;
        }
        const maxParticipantsItems = Array(MAX_ACTIVE_PARTICIPANTS).fill(0)
            .map((no, index) => {
            return {
                value: index + 1,
                label: `${index + 1}`
            };
        });
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'maxStageParticipants' },
            React.createElement("div", { className: 'dropdown-menu' },
                React.createElement(Select, { label: t('settings.maxStageParticipants'), onChange: this._onMaxStageParticipantsSelect, options: maxParticipantsItems, value: maxStageParticipants }))));
    }
    /**
     * Returns the React element that needs to be displayed on the right half of the more tabs.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderSettingsRight() {
        const { showLanguageSettings } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane right', key: 'settings-sub-pane-right' },
            showLanguageSettings && this._renderLanguageSelect(),
            this._renderFramerateSelect(),
            this._renderMaxStageParticipantsSelect()));
    }
    /**
     * Returns the React element that needs to be displayed on the left half of the more tabs.
     *
     * @returns {ReactElement}
     */
    _renderSettingsLeft() {
        const { disableHideSelfView, showNotificationsSettings, showPrejoinSettings } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane left', key: 'settings-sub-pane-left' }, !disableHideSelfView && this._renderSelfViewCheckbox()));
    }
}
export default translate(MoreTab);
