import React from 'react';
import { translate } from '../../../base/i18n/functions';
import { connect } from '../../../base/redux/functions';
import Dialog from '../../../base/ui/components/web/Dialog';
/**
 * Implements the Logout dialog.
 *
 * @param {Object} props - The props of the component.
 * @returns {React$Element}.
 */
function LogoutDialog({ onLogout, t }) {
    return (React.createElement(Dialog, { ok: { translationKey: 'dialog.Yes' }, onSubmit: onLogout, titleKey: t('dialog.logoutTitle') },
        React.createElement("div", null, t('dialog.logoutQuestion'))));
}
export default translate(connect()(LogoutDialog));
