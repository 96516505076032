// @ts-expect-error
import VideoLayout from '../../../modules/UI/videolayout/VideoLayout';
import { getParticipantById } from '../base/participants/functions';
import { OPEN_CHAT } from './actionTypes';
import { closeChat } from './actions.any';
export * from './actions.any';
/**
 * Displays the chat panel.
 *
 * @param {Object} participant - The recipient for the private chat.
 * @returns {{
 *     participant: Participant,
 *     type: OPEN_CHAT
 * }}
 */
export function openChat(participant) {
    return function (dispatch) {
        dispatch({
            participant,
            type: OPEN_CHAT
        });
    };
}
/**
 * Displays the chat panel for a participant identified by an id.
 *
 * @param {string} id - The id of the participant.
 * @returns {{
 *     participant: Participant,
 *     type: OPEN_CHAT
 * }}
 */
export function openChatById(id) {
    return function (dispatch, getState) {
        const participant = getParticipantById(getState(), id);
        return dispatch({
            participant,
            type: OPEN_CHAT
        });
    };
}
/**
 * Toggles display of the chat panel.
 *
 * @returns {Function}
 */
export function toggleChat() {
    return (dispatch, getState) => {
        const isOpen = getState()['features/chat'].isOpen;
        if (isOpen) {
            dispatch(closeChat());
            window.parent.postMessage('meetCloseChat', '*');
        }
        else {
            dispatch(openChat());
            window.parent.postMessage('meetOpenChat', '*');
        }
        // Recompute the large video size whenever we toggle the chat, as it takes chat state into account.
        VideoLayout.onResize();
    };
}
