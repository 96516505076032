import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { showOverflowDrawer } from '../../../../toolbox/functions.web';
import Icon from '../../../icons/components/Icon';
import { withPixelLineHeight } from '../../../styles/functions.web';
const useStyles = makeStyles()(theme => {
    return {
        contextMenuItem: {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            minHeight: '40px',
            padding: '10px 16px',
            boxSizing: 'border-box',
            '& > *:not(:last-child)': {
                marginRight: theme.spacing(3)
            },
            '&:hover': {
                backgroundColor: theme.palette.ui02
            },
            '&:active': {
                backgroundColor: theme.palette.ui03
            },
            '&:focus': {
                boxShadow: `inset 0 0 0 2px ${theme.palette.action01Hover}`
            }
        },
        selected: {
            borderLeft: `3px solid ${theme.palette.action01Hover}`,
            paddingLeft: '13px',
            backgroundColor: theme.palette.ui02
        },
        contextMenuItemDisabled: {
            pointerEvents: 'none'
        },
        contextMenuItemDrawer: {
            padding: '13px 16px'
        },
        contextMenuItemIcon: {
            '& svg': {
                fill: theme.palette.icon01
            }
        },
        text: {
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            color: theme.palette.text01
        },
        drawerText: {
            ...withPixelLineHeight(theme.typography.bodyShortRegularLarge)
        }
    };
});
const ContextMenuItem = ({ accessibilityLabel, children, className, customIcon, disabled, id, icon, onClick, onKeyDown, onKeyPress, selected, testId, text, textClassName }) => {
    const { classes: styles, cx } = useStyles();
    const _overflowDrawer = useSelector(showOverflowDrawer);
    return (React.createElement("div", { "aria-disabled": disabled, "aria-label": accessibilityLabel, className: cx(styles.contextMenuItem, _overflowDrawer && styles.contextMenuItemDrawer, disabled && styles.contextMenuItemDisabled, selected && styles.selected, className), "data-testid": testId, id: id, key: text, onClick: disabled ? undefined : onClick, onKeyDown: disabled ? undefined : onKeyDown, onKeyPress: disabled ? undefined : onKeyPress, role: 'menuitem' },
        customIcon ? customIcon
            : icon && React.createElement(Icon, { className: styles.contextMenuItemIcon, size: 20, src: icon }),
        text && React.createElement("span", { className: cx(styles.text, textClassName) }, text),
        children));
};
export default ContextMenuItem;
