import { IconConnection } from '../../base/icons/svg';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import { AbstractButton } from '../../base/toolbox/components';
/**
 * Implementation of a button for opening speaker stats dialog.
 */
class AbstractSpeakerStatsButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.speakerStats';
        this.icon = IconConnection;
        this.label = 'toolbar.speakerStats';
        this.tooltip = 'toolbar.speakerStats';
    }
}
export default AbstractSpeakerStatsButton;
