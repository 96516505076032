import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../i18n/functions';
import Icon from '../../../icons/components/Icon';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import { Tooltip } from '../../../tooltip';
const useStyles = makeStyles()(() => {
    return {
        indicator: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    };
});
/**
 * React {@code Component} for showing an icon with a tooltip.
 *
 * @returns {ReactElement}
 */
const BaseIndicator = ({ className = '', icon, iconClassName, iconColor, iconId, iconSize, id = '', t, tooltipKey, tooltipPosition = 'top' }) => {
    const { classes: styles } = useStyles();
    const style = {};
    if (iconSize) {
        style.fontSize = iconSize;
    }
    return (React.createElement("div", { className: styles.indicator },
        React.createElement(Tooltip, { content: t(tooltipKey), position: tooltipPosition },
            React.createElement("span", { className: className, id: id },
                React.createElement(Icon, { className: iconClassName, color: iconColor, id: iconId, src: icon, style: style })))));
};
export default translate(BaseIndicator);
