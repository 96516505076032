import { MEDIA_TYPE, VIDEO_TYPE } from '../media/constants';
import { getParticipantById, isScreenShareParticipant } from '../participants/functions';
import { getTrackByMediaTypeAndParticipant, getVideoTrackByParticipant } from '../tracks/functions';
/**
 * Indicates whether the test mode is enabled. When it's enabled
 * {@link TestHint} and other components from the testing package will be
 * rendered in various places across the app to help with automatic testing.
 *
 * @param {IReduxState} state - The redux store state.
 * @returns {boolean}
 */
export function isTestModeEnabled(state) {
    const testingConfig = state['features/base/config'].testing;
    return Boolean(testingConfig?.testMode);
}
/**
 * Returns the video type of the remote participant's video.
 *
 * @param {IStore} store - The redux store.
 * @param {string} id - The participant ID for the remote video.
 * @returns {VIDEO_TYPE}
 */
export function getRemoteVideoType({ getState }, id) {
    const state = getState();
    const participant = getParticipantById(state, id);
    if (isScreenShareParticipant(participant)) {
        return VIDEO_TYPE.DESKTOP;
    }
    return getTrackByMediaTypeAndParticipant(state['features/base/tracks'], MEDIA_TYPE.VIDEO, id)?.videoType;
}
/**
 * Returns whether the last media event received for large video indicates that the video is playing, if not muted.
 *
 * @param {IStore} store - The redux store.
 * @returns {boolean}
 */
export function isLargeVideoReceived({ getState }) {
    const state = getState();
    const largeVideoParticipantId = state['features/large-video'].participantId ?? '';
    const largeVideoParticipant = getParticipantById(state, largeVideoParticipantId ?? '');
    const videoTrack = getVideoTrackByParticipant(state, largeVideoParticipant);
    const lastMediaEvent = state['features/large-video']?.lastMediaEvent;
    return Boolean(videoTrack && !videoTrack.muted
        && (lastMediaEvent === 'playing' || lastMediaEvent === 'canplaythrough'));
}
/**
 * Returns whether the last media event received for a remote video indicates that the video is playing, if not muted.
 *
 * @param {IStore} store - The redux store.
 * @param {string} id - The participant ID for the remote video.
 * @returns {boolean}
 */
export function isRemoteVideoReceived({ getState }, id) {
    const state = getState();
    const participant = getParticipantById(state, id);
    const videoTrack = getVideoTrackByParticipant(state, participant);
    const lastMediaEvent = videoTrack?.lastMediaEvent;
    return Boolean(videoTrack && !videoTrack.muted
        && (lastMediaEvent === 'playing' || lastMediaEvent === 'canplaythrough'));
}
