import React from 'react';
// @ts-ignore
import { AbstractDialogTab } from '../../../base/dialog';
import { translate } from '../../../base/i18n/functions';
import Checkbox from '../../../base/ui/components/web/Checkbox';
/**
 * React {@code Component} for modifying the local user's sound settings.
 *
 * @augments Component
 */
class SoundsTab extends AbstractDialogTab {
    /**
     * Initializes a new {@code SoundsTab} instance.
     *
     * @param {Props} props - The React {@code Component} props to initialize
     * the new {@code SoundsTab} instance with.
     */
    constructor(props) {
        super(props);
        // Bind event handlers so they are only bound once for every instance.
        this._onChange = this._onChange.bind(this);
    }
    /**
     * Changes a sound setting state.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onChange({ target }) {
        super._onChange({ [target.name]: target.checked });
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { soundsIncomingMessage, soundsParticipantJoined, soundsParticipantKnocking, soundsParticipantLeft, soundsTalkWhileMuted, soundsReactions, enableReactions, moderatorMutedSoundsReactions, t // @ts-ignore
         } = this.props;
        return (React.createElement("div", { className: 'settings-sub-pane-element', key: 'sounds' },
            React.createElement("h2", { className: 'mock-atlaskit-label' }, t('settings.playSounds')),
            enableReactions && React.createElement(Checkbox, { checked: soundsReactions, className: 'settings-checkbox', disabled: moderatorMutedSoundsReactions, label: t('settings.reactions'), name: 'soundsReactions', onChange: this._onChange }),
            React.createElement(Checkbox, { checked: soundsIncomingMessage, className: 'settings-checkbox', label: t('settings.incomingMessage'), name: 'soundsIncomingMessage', onChange: this._onChange }),
            React.createElement(Checkbox, { checked: soundsParticipantJoined, className: 'settings-checkbox', label: t('settings.participantJoined'), name: 'soundsParticipantJoined', onChange: this._onChange }),
            React.createElement(Checkbox, { checked: soundsParticipantLeft, className: 'settings-checkbox', label: t('settings.participantLeft'), name: 'soundsParticipantLeft', onChange: this._onChange }),
            React.createElement(Checkbox, { checked: soundsTalkWhileMuted, className: 'settings-checkbox', label: t('settings.talkWhileMuted'), name: 'soundsTalkWhileMuted', onChange: this._onChange }),
            React.createElement(Checkbox, { checked: soundsParticipantKnocking, className: 'settings-checkbox', label: t('settings.participantKnocking'), name: 'soundsParticipantKnocking', onChange: this._onChange })));
    }
}
// @ts-ignore
export default translate(SoundsTab);
