/* eslint-disable @typescript-eslint/naming-convention */
import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { createColorTokens } from './utils';
/**
 * Creates a MUI theme based on local UI tokens.
 *
 * @param {Object} arg - The ui tokens.
 * @returns {Object}
 */
export function createWebTheme({ font, colors, colorMap, shape, spacing, typography, breakpoints }) {
    return createTheme(adaptV4Theme({
        spacing,
        palette: createColorTokens(colorMap, colors),
        shape,
        // @ts-ignore
        typography: {
            // @ts-ignore
            font,
            ...typography
        },
        breakpoints
    }));
}
/**
 * Find the first styled ancestor component of an element.
 *
 * @param {HTMLElement|null} target - Element to look up.
 * @param {string} cssClass - Styled component reference.
 * @returns {HTMLElement|null} Ancestor.
 */
export const findAncestorByClass = (target, cssClass) => {
    if (!target || target.classList.contains(cssClass)) {
        return target;
    }
    return findAncestorByClass(target.parentElement, cssClass);
};
