import React, { Component } from 'react';
import { translate } from '../../../../base/i18n/functions';
import Icon from '../../../../base/icons/components/Icon';
import { IconCopy } from '../../../../base/icons/svg';
import { copyText } from '../../../../base/util/copyText.web';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import { _formatConferenceIDPin } from '../../../_utils';
/**
 * React {@code Component} responsible for displaying a telephone number and
 * conference ID for dialing into a conference.
 *
 * @augments Component
 */
class DialInNumber extends Component {
    /**
     * Initializes a new DialInNumber instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        // Bind event handler so it is only bound once for every instance.
        this._onCopyText = this._onCopyText.bind(this);
        this._onCopyTextKeyPress = this._onCopyTextKeyPress.bind(this);
    }
    /**
     * Copies the dial-in information to the clipboard.
     *
     * @returns {void}
     */
    _onCopyText() {
        const { conferenceID, phoneNumber, t } = this.props;
        const dialInLabel = t('info.dialInNumber');
        const passcode = t('info.dialInConferenceID');
        const conferenceIDPin = `${_formatConferenceIDPin(conferenceID)}#`;
        const textToCopy = `${dialInLabel} ${phoneNumber} ${passcode} ${conferenceIDPin}`;
        copyText(textToCopy);
    }
    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onCopyTextKeyPress(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            this._onCopyText();
        }
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { conferenceID, phoneNumber, t } = this.props;
        return (React.createElement("div", { className: 'dial-in-number' },
            React.createElement("div", null,
                React.createElement("span", { className: 'phone-number' },
                    React.createElement("span", { className: 'info-label' }, t('info.dialInNumber')),
                    React.createElement("span", { className: 'spacer' }, "\u00A0"),
                    React.createElement("span", { className: 'info-value' }, phoneNumber)),
                React.createElement("span", { className: 'spacer' }, "\u00A0"),
                React.createElement("span", { className: 'conference-id' },
                    React.createElement("span", { className: 'info-label' }, t('info.dialInConferenceID')),
                    React.createElement("span", { className: 'spacer' }, "\u00A0"),
                    React.createElement("span", { className: 'info-value' }, `${_formatConferenceIDPin(conferenceID)}#`))),
            React.createElement("a", { "aria-label": t('info.copyNumber'), className: 'dial-in-copy', onClick: this._onCopyText, onKeyPress: this._onCopyTextKeyPress, role: 'button', tabIndex: 0 },
                React.createElement(Icon, { src: IconCopy }))));
    }
}
export default translate(DialInNumber);
