import { Component } from 'react';
import { createRemoteVideoMenuButtonEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { getParticipantById } from '../../base/participants/functions';
/**
 * Abstract dialog to confirm granting moderator to a participant.
 */
export default class AbstractGrantModeratorDialog extends Component {
    /**
     * Initializes a new {@code AbstractGrantModeratorDialog} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._onSubmit = this._onSubmit.bind(this);
    }
    /**
     * Callback for the confirm button.
     *
     * @private
     * @returns {boolean} - True (to note that the modal should be closed).
     */
    _onSubmit() {
        const { dispatch, participantID, participantEmail } = this.props;
        sendAnalytics(createRemoteVideoMenuButtonEvent('grant.moderator.button', {
            'participant_id': participantID
        }));
        //dispatch(grantModerator(participantID));
        window.parent.postMessage({
            event_id: 'grantModeratorMeet',
            type: participantEmail
        }, '*');
        return true;
    }
}
/**
 * Maps (parts of) the Redux state to the associated {@code AbstractMuteEveryoneDialog}'s props.
 *
 * @param {IReduxState} state - The redux state.
 * @param {Object} ownProps - The properties explicitly passed to the component.
 * @returns {IProps}
 */
export function abstractMapStateToProps(state, ownProps) {
    const participant = getParticipantById(state, ownProps.participantID ?? '');
    const participantName = participant?.name;
    const participantEmail = participant?.email;
    return {
        participantName: participantName,
        participantEmail: participantEmail
    };
}
