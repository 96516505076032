import Tooltip from '@atlaskit/tooltip';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconExclamationTriangle } from '../../../base/icons/svg';
import Label from '../../../base/label/components/web/Label';
import { COLORS } from '../../../base/label/constants';
import AbstractInsecureRoomNameLabel, { _mapStateToProps } from '../AbstractInsecureRoomNameLabel';
/**
 * Renders a label indicating that we are in a room with an insecure name.
 */
class InsecureRoomNameLabel extends AbstractInsecureRoomNameLabel {
    /**
     * Renders the platform dependent content.
     *
     * @inheritdoc
     */
    _render() {
        return (React.createElement(Tooltip, { content: this.props.t('security.insecureRoomNameWarning'), position: 'bottom' },
            React.createElement(Label, { color: COLORS.red, icon: IconExclamationTriangle })));
    }
}
export default translate(connect(_mapStateToProps)(InsecureRoomNameLabel));
