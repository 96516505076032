/**
 * The types of the buttons.
 */
export var BUTTON_TYPES;
(function (BUTTON_TYPES) {
    BUTTON_TYPES["DESTRUCTIVE"] = "destructive";
    BUTTON_TYPES["PRIMARY"] = "primary";
    BUTTON_TYPES["SECONDARY"] = "secondary";
    BUTTON_TYPES["TERTIARY"] = "tertiary";
})(BUTTON_TYPES || (BUTTON_TYPES = {}));
/**
 * The modes of the buttons.
 */
export const BUTTON_MODES = {
    CONTAINED: 'contained'
};
