import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import AbstractPollResults from '../AbstractPollResults';
const useStyles = makeStyles()(theme => {
    return {
        container: {
            margin: '24px',
            padding: '16px',
            backgroundColor: theme.palette.ui02,
            borderRadius: '8px'
        },
        header: {
            marginBottom: '16px'
        },
        question: {
            ...withPixelLineHeight(theme.typography.heading6),
            color: theme.palette.text01,
            marginBottom: '8px'
        },
        creator: {
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            color: theme.palette.text02
        },
        resultList: {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            '& li': {
                marginBottom: '16px'
            }
        },
        answerName: {
            display: 'flex',
            flexShrink: 1,
            overflowWrap: 'anywhere',
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            color: theme.palette.text01,
            marginBottom: '4px'
        },
        answerResultContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minWidth: '10em'
        },
        barContainer: {
            backgroundColor: theme.palette.ui03,
            borderRadius: '4px',
            height: '6px',
            maxWidth: '160px',
            width: '158px',
            flexGrow: 1,
            marginTop: '2px'
        },
        bar: {
            height: '6px',
            borderRadius: '4px',
            backgroundColor: theme.palette.action01
        },
        voteCount: {
            flex: 1,
            textAlign: 'right',
            ...withPixelLineHeight(theme.typography.bodyShortBold),
            color: theme.palette.text01
        },
        voters: {
            margin: 0,
            marginTop: '4px',
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.ui03,
            borderRadius: theme.shape.borderRadius,
            padding: '8px 16px',
            '& li': {
                ...withPixelLineHeight(theme.typography.bodyShortRegular),
                color: theme.palette.text01,
                margin: 0,
                marginBottom: '2px',
                '&:last-of-type': {
                    marginBottom: 0
                }
            }
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            '& button': {
                border: 0,
                backgroundColor: 'transparent',
                ...withPixelLineHeight(theme.typography.bodyShortRegular),
                color: theme.palette.link01
            }
        }
    };
});
/**
 * Component that renders the poll results.
 *
 * @param {Props} props - The passed props.
 * @returns {React.Node}
 */
const PollResults = ({ answers, changeVote, creatorName, haveVoted, showDetails, question, t, toggleIsDetailed }) => {
    const { classes } = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.header },
            React.createElement("div", { className: classes.question }, question),
            React.createElement("div", { className: classes.creator }, t('polls.by', { name: creatorName }))),
        React.createElement("ul", { className: classes.resultList }, answers.map(({ name, percentage, voters, voterCount }, index) => (React.createElement("li", { key: index },
            React.createElement("div", { className: classes.answerName }, name),
            React.createElement("div", { className: classes.answerResultContainer },
                React.createElement("span", { className: classes.barContainer },
                    React.createElement("div", { className: classes.bar, style: { width: `${percentage}%` } })),
                React.createElement("div", { className: classes.voteCount },
                    voterCount,
                    "(",
                    percentage,
                    "%)")),
            showDetails && voters && voterCount > 0
                && React.createElement("ul", { className: classes.voters }, voters.map(voter => React.createElement("li", { key: voter?.id }, voter?.name))))))),
        React.createElement("div", { className: classes.buttonsContainer },
            React.createElement("button", { onClick: toggleIsDetailed }, showDetails ? t('polls.results.hideDetailedResults') : t('polls.results.showDetailedResults')),
            React.createElement("button", { onClick: changeVote }, haveVoted ? t('polls.results.changeVote') : t('polls.results.vote')))));
};
/*
 * We apply AbstractPollResults to fill in the AbstractProps common
 * to both the web and native implementations.
 */
// eslint-disable-next-line new-cap
export default AbstractPollResults(PollResults);
