import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { translate } from '../../../base/i18n/functions';
import Icon from '../../../base/icons/components/Icon';
import { IconVolumeUp } from '../../../base/icons/svg';
import { VOLUME_SLIDER_SCALE } from '../../constants';
const styles = (theme) => {
    return {
        container: {
            minHeight: '40px',
            minWidth: '180px',
            width: '100%',
            boxSizing: 'border-box',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 16px',
            '&:hover': {
                backgroundColor: theme.palette.ui02
            }
        },
        icon: {
            minWidth: '20px',
            marginRight: '16px',
            position: 'relative'
        },
        sliderContainer: {
            position: 'relative',
            width: '100%'
        },
        slider: {
            position: 'absolute',
            width: '100%',
            top: '50%',
            transform: 'translate(0, -50%)'
        }
    };
};
/**
 * Implements a React {@link Component} which displays an input slider for
 * adjusting the local volume of a remote participant.
 *
 * @augments Component
 */
class VolumeSlider extends Component {
    /**
     * Initializes a new {@code VolumeSlider} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this.state = {
            volumeLevel: (props.initialValue || 0) * VOLUME_SLIDER_SCALE
        };
        // Bind event handlers so they are only bound once for every instance.
        this._onVolumeChange = this._onVolumeChange.bind(this);
    }
    /**
     * Click handler.
     *
     * @param {MouseEvent} e - Click event.
     * @returns {void}
     */
    _onClick(e) {
        e.stopPropagation();
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { classes } = this.props;
        return (React.createElement("div", { "aria-label": this.props.t('volumeSlider'), className: clsx('popupmenu__contents', classes.container), onClick: this._onClick },
            React.createElement("span", { className: classes.icon },
                React.createElement(Icon, { size: 22, src: IconVolumeUp })),
            React.createElement("div", { className: classes.sliderContainer },
                React.createElement("input", { "aria-valuemax": VOLUME_SLIDER_SCALE, "aria-valuemin": 0, "aria-valuenow": this.state.volumeLevel, className: clsx('popupmenu__volume-slider', classes.slider), max: VOLUME_SLIDER_SCALE, min: 0, onChange: this._onVolumeChange, tabIndex: 0, type: 'range', value: this.state.volumeLevel }))));
    }
    /**
     * Sets the internal state of the volume level for the volume slider.
     * Invokes the prop onVolumeChange to notify of volume changes.
     *
     * @param {Object} event - DOM Event for slider change.
     * @private
     * @returns {void}
     */
    _onVolumeChange(event) {
        const volumeLevel = Number(event.currentTarget.value);
        this.props.onChange(volumeLevel / VOLUME_SLIDER_SCALE);
        this.setState({ volumeLevel });
    }
}
export default translate(withStyles(styles)(VolumeSlider));
