import React from 'react';
import { translate } from '../../../base/i18n/functions';
import { connect } from '../../../base/redux/functions';
import Dialog from '../../../base/ui/components/web/Dialog';
import AbstractGrantModeratorDialog, { abstractMapStateToProps } from '../AbstractGrantModeratorDialog';
/**
 * Dialog to confirm a grant moderator action.
 */
class GrantModeratorDialog extends AbstractGrantModeratorDialog {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        return (React.createElement(Dialog, { ok: { translationKey: 'dialog.Yes' }, onSubmit: this._onSubmit, titleKey: 'dialog.grantModeratorTitle' },
            React.createElement("div", null, this.props.t('dialog.grantModeratorDialog', { participantName: this.props.participantName }))));
    }
}
export default translate(connect(abstractMapStateToProps)(GrantModeratorDialog));
