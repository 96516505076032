import React, { useCallback } from 'react';
// @ts-ignore
import { Container } from '../../react/base';
import { styleTypeToObject } from '../../styles/functions';
export const DEFAULT_COLOR = navigator.product === 'ReactNative' ? 'white' : undefined;
export const DEFAULT_SIZE = navigator.product === 'ReactNative' ? 36 : 22;
/**
 * Implements an Icon component that takes a loaded SVG file as prop and renders it as an icon.
 *
 * @param {IProps} props - The props of the component.
 * @returns {ReactElement}
 */
export default function Icon(props) {
    const { className, color, id, containerId, onClick, size, src: IconComponent, style, ariaHasPopup, ariaLabel, ariaDisabled, ariaExpanded, ariaControls, tabIndex, ariaPressed, ariaDescribedBy, role, onKeyPress, onKeyDown, ...rest } = props;
    const { color: styleColor, fontSize: styleSize, ...restStyle
    // @ts-ignore
     } = styleTypeToObject(style ?? {});
    const calculatedColor = color ?? styleColor ?? DEFAULT_COLOR;
    const calculatedSize = size ?? styleSize ?? DEFAULT_SIZE;
    const onKeyPressHandler = useCallback(e => {
        if ((e.key === 'Enter' || e.key === ' ') && onClick) {
            e.preventDefault();
            onClick(e);
        }
        else if (onKeyPress) {
            onKeyPress(e);
        }
    }, [onClick, onKeyPress]);
    const jitsiIconClassName = calculatedColor ? 'jitsi-icon' : 'jitsi-icon jitsi-icon-default';
    return (React.createElement(Container, { ...rest, "aria-controls": ariaControls, "aria-describedby": ariaDescribedBy, "aria-disabled": ariaDisabled, "aria-expanded": ariaExpanded, "aria-haspopup": ariaHasPopup, "aria-label": ariaLabel, "aria-pressed": ariaPressed, className: `${jitsiIconClassName} ${className || ''}`, id: containerId, onClick: onClick, onKeyDown: onKeyDown, onKeyPress: onKeyPressHandler, role: role, style: restStyle, tabIndex: tabIndex },
        React.createElement(IconComponent, { fill: calculatedColor, height: calculatedSize, id: id, width: calculatedSize })));
}
Icon.defaultProps = {
    className: ''
};
