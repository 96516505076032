/* eslint-disable lines-around-comment */
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../../base/i18n/functions';
import Icon from '../../../../base/icons/components/Icon';
import { IconArrowLeft } from '../../../../base/icons/svg';
import Button from '../../../../base/ui/components/web/Button';
// @ts-ignore
import { getCountryCodeFromPhone } from '../../../utils';
// @ts-ignore
import Label from '../Label';
const useStyles = makeStyles()(theme => {
    return {
        dialInDialog: {
            textAlign: 'center',
            '& .prejoin-dialog-dialin-header': {
                alignItems: 'center',
                margin: `${theme.spacing(3)} 0 ${theme.spacing(5)} ${theme.spacing(3)}`,
                display: 'flex'
            },
            '& .prejoin-dialog-dialin-icon': {
                marginRight: theme.spacing(3)
            },
            '& .prejoin-dialog-dialin-num': {
                background: '#3e474f',
                borderRadius: '4px',
                display: 'inline-block',
                fontSize: '15px',
                lineHeight: '24px',
                margin: theme.spacing(1),
                padding: theme.spacing(2),
                '& .prejoin-dialog-dialin-num-container': {
                    minHeight: '48px',
                    margin: `${theme.spacing(2)} 0`
                }
            },
            '& .prejoin-dialog-dialin-link': {
                color: '#6FB1EA',
                cursor: 'pointer',
                display: 'inline-block',
                fontSize: '13px',
                lineHeight: '20px',
                marginBottom: theme.spacing(4)
            },
            '& .prejoin-dialog-dialin-spaced-label': {
                marginBottom: theme.spacing(3),
                marginTop: '28px'
            },
            '& .prejoin-dialog-dialin-btns > div': {
                marginBottom: theme.spacing(3)
            }
        }
    };
});
/**
 * This component displays the dialog with all the information
 * to join a meeting by calling it.
 *
 * @param {IProps} props - The props of the component.
 * @returns {React$Element}
 */
function DialinDialog(props) {
    const { number, onBack, onPrimaryButtonClick, onSmallTextClick, onTextButtonClick, passCode, t } = props;
    const { classes } = useStyles();
    const flagClassName = `prejoin-dialog-flag iti-flag ${getCountryCodeFromPhone(number)}`;
    return (React.createElement("div", { className: classes.dialInDialog },
        React.createElement("div", { className: 'prejoin-dialog-dialin-header' },
            React.createElement(Icon, { className: 'prejoin-dialog-icon prejoin-dialog-dialin-icon', onClick: onBack, role: 'button', size: 24, src: IconArrowLeft }),
            React.createElement("div", { className: 'prejoin-dialog-title' }, t('prejoin.dialInMeeting'))),
        React.createElement(Label, { number: 1 }, t('prejoin.dialInPin')),
        React.createElement("div", { className: 'prejoin-dialog-dialin-num-container' },
            React.createElement("div", { className: 'prejoin-dialog-dialin-num' },
                React.createElement("div", { className: flagClassName }),
                React.createElement("span", null, number)),
            React.createElement("div", { className: 'prejoin-dialog-dialin-num' }, passCode)),
        React.createElement("div", null,
            React.createElement("span", { className: 'prejoin-dialog-dialin-link', onClick: onSmallTextClick }, t('prejoin.viewAllNumbers'))),
        React.createElement("div", { className: 'prejoin-dialog-delimiter' }),
        React.createElement(Label, { className: 'prejoin-dialog-dialin-spaced-label', number: 2 }, t('prejoin.connectedWithAudioQ')),
        React.createElement("div", { className: 'prejoin-dialog-dialin-btns' },
            React.createElement(Button, { className: 'prejoin-dialog-btn', fullWidth: true, labelKey: 'prejoin.joinMeeting', onClick: onPrimaryButtonClick, type: 'primary' }),
            React.createElement(Button, { className: 'prejoin-dialog-btn', fullWidth: true, labelKey: 'dialog.Cancel', onClick: onTextButtonClick, type: 'tertiary' }))));
}
export default translate(DialinDialog);
