import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openDialog } from '../../../../base/dialog/actions';
import { translate } from '../../../../base/i18n/functions';
import { IconImage } from '../../../../base/icons/svg';
import Video from '../../../../base/media/components/Video.web';
import { equals } from '../../../../base/redux/functions';
import { updateSettings } from '../../../../base/settings/actions';
import Checkbox from '../../../../base/ui/components/web/Checkbox';
import ContextMenu from '../../../../base/ui/components/web/ContextMenu';
import ContextMenuItem from '../../../../base/ui/components/web/ContextMenuItem';
import ContextMenuItemGroup from '../../../../base/ui/components/web/ContextMenuItemGroup';
import VirtualBackgroundDialog from '../../../../virtual-background/components/VirtualBackgroundDialog';
import { createLocalVideoTracks } from '../../../functions.web';
const videoClassName = 'video-preview-video flipVideoX';
/**
 * Implements a React {@link Component} which displays a list of video
 * previews to choose from.
 *
 * @augments Component
 */
class VideoSettingsContent extends Component {
    /**
     * Initializes a new {@code VideoSettingsContent} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this._onToggleFlip = this._onToggleFlip.bind(this);
        this.state = {
            trackData: new Array(props.videoDeviceIds.length).fill({
                jitsiTrack: null
            })
        };
    }
    /**
     * Toggles local video flip state.
     *
     * @returns {void}
     */
    _onToggleFlip() {
        const { localFlipX, changeFlip } = this.props;
        changeFlip(!localFlipX);
    }
    /**
     * Creates and updates the track data.
     *
     * @returns {void}
     */
    async _setTracks() {
        this._disposeTracks(this.state.trackData);
        const trackData = await createLocalVideoTracks(this.props.videoDeviceIds, 5000);
        // In case the component gets unmounted before the tracks are created
        // avoid a leak by not setting the state
        if (this._componentWasUnmounted) {
            this._disposeTracks(trackData);
        }
        else {
            this.setState({
                trackData
            });
        }
    }
    /**
     * Destroys all the tracks from trackData object.
     *
     * @param {Object[]} trackData - An array of tracks that are to be disposed.
     * @returns {Promise<void>}
     */
    _disposeTracks(trackData) {
        trackData.forEach(({ jitsiTrack }) => {
            jitsiTrack?.dispose();
        });
    }
    /**
     * Returns the click handler used when selecting the video preview.
     *
     * @param {string} deviceId - The id of the camera device.
     * @returns {Function}
     */
    _onEntryClick(deviceId) {
        return () => {
            this.props.setVideoInputDevice(deviceId);
            this.props.toggleVideoSettings();
        };
    }
    /**
     * Renders a preview entry.
     *
     * @param {Object} data - The track data.
     * @param {number} index - The index of the entry.
     * @returns {React$Node}
     */
    _renderPreviewEntry(data, index) {
        const { error, jitsiTrack, deviceId } = data;
        const { currentCameraDeviceId, t } = this.props;
        const isSelected = deviceId === currentCameraDeviceId;
        const key = `vp-${index}`;
        const className = 'video-preview-entry';
        const tabIndex = '0';
        if (error) {
            return (React.createElement("div", { className: className, key: key, tabIndex: -1 },
                React.createElement("div", { className: 'video-preview-error' }, t(error))));
        }
        const props = {
            className,
            key,
            tabIndex
        };
        const label = jitsiTrack?.getTrackLabel();
        if (isSelected) {
            props['aria-checked'] = true;
            props.className = `${className} video-preview-entry--selected`;
        }
        else {
            props.onClick = this._onEntryClick(deviceId);
            props.onKeyPress = (e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    props.onClick();
                }
            };
        }
        return (React.createElement("div", { ...props, role: 'radio' },
            React.createElement("div", { className: 'video-preview-label' }, label && React.createElement("div", { className: 'video-preview-label-text' },
                React.createElement("span", null, label))),
            React.createElement(Video, { className: videoClassName, playsinline: true, videoTrack: { jitsiTrack } })));
    }
    /**
     * Implements React's {@link Component#componentDidMount}.
     *
     * @inheritdoc
     */
    componentDidMount() {
        this._setTracks();
    }
    /**
     * Implements React's {@link Component#componentWillUnmount}.
     *
     * @inheritdoc
     */
    componentWillUnmount() {
        this._componentWasUnmounted = true;
        this._disposeTracks(this.state.trackData);
    }
    /**
     * Implements React's {@link Component#componentDidUpdate}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        if (!equals(this.props.videoDeviceIds, prevProps.videoDeviceIds)) {
            this._setTracks();
        }
    }
    /**
     * Implements React's {@link Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { trackData } = this.state;
        const { selectBackground, t, localFlipX } = this.props;
        return (React.createElement(ContextMenu, { "aria-labelledby": 'video-settings-button', className: 'video-preview-container', hidden: false, id: 'video-settings-dialog', role: 'radiogroup', tabIndex: -1 },
            React.createElement(ContextMenuItemGroup, null, trackData.map((data, i) => this._renderPreviewEntry(data, i))),
            React.createElement(ContextMenuItemGroup, null,
                React.createElement(ContextMenuItem, { accessibilityLabel: 'virtualBackground.title', icon: IconImage, onClick: selectBackground, text: t('virtualBackground.title') }),
                React.createElement("div", { className: 'video-preview-checkbox-container', 
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick: e => e.stopPropagation() },
                    React.createElement(Checkbox, { checked: localFlipX, label: t('videothumbnail.mirrorVideo'), onChange: this._onToggleFlip })))));
    }
}
const mapStateToProps = (state) => {
    const { localFlipX } = state['features/base/settings'];
    return {
        localFlipX: Boolean(localFlipX)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        selectBackground: () => dispatch(openDialog(VirtualBackgroundDialog)),
        changeFlip: (flip) => {
            dispatch(updateSettings({
                localFlipX: flip
            }));
        }
    };
};
export default translate(connect(mapStateToProps, mapDispatchToProps)(VideoSettingsContent));
