/* eslint-disable lines-around-comment */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { createToolbarEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import Popover from '../../../base/popover/components/Popover.web';
// @ts-ignore
import { ReactionEmoji, ReactionsMenu } from '../../../reactions/components';
import { REACTIONS_MENU_HEIGHT } from '../../../reactions/constants';
import { getReactionsQueue } from '../../../reactions/functions.any';
import { DRAWER_MAX_HEIGHT } from '../../constants';
import { showOverflowDrawer } from '../../functions.web';
import Drawer from './Drawer';
import JitsiPortal from './JitsiPortal';
// @ts-ignore
import OverflowToggleButton from './OverflowToggleButton';
const useStyles = makeStyles()(() => {
    return {
        overflowMenuDrawer: {
            overflowY: 'auto',
            height: `calc(${DRAWER_MAX_HEIGHT} - ${REACTIONS_MENU_HEIGHT}px - 16px)`
        }
    };
});
const OverflowMenuButton = ({ children, isOpen, onVisibilityChange, showMobileReactions }) => {
    const { classes } = useStyles();
    const overflowDrawer = useSelector(showOverflowDrawer);
    const reactionsQueue = useSelector(getReactionsQueue);
    const onCloseDialog = useCallback(() => {
        onVisibilityChange(false);
    }, [onVisibilityChange]);
    const onOpenDialog = useCallback(() => {
        onVisibilityChange(true);
    }, [onVisibilityChange]);
    const onEscClick = useCallback((event) => {
        if (event.key === 'Escape' && isOpen) {
            event.preventDefault();
            event.stopPropagation();
            onCloseDialog();
        }
    }, [onCloseDialog]);
    const toggleDialogVisibility = useCallback(() => {
        sendAnalytics(createToolbarEvent('overflow'));
        onVisibilityChange(!isOpen);
    }, [isOpen, onVisibilityChange]);
    return (React.createElement("div", { className: 'toolbox-button-wth-dialog context-menu' }, overflowDrawer ? (React.createElement(React.Fragment, null,
        React.createElement(OverflowToggleButton, { handleClick: toggleDialogVisibility, isOpen: isOpen, onKeyDown: onEscClick }),
        React.createElement(JitsiPortal, null,
            React.createElement(Drawer, { isOpen: isOpen, onClose: onCloseDialog },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classes.overflowMenuDrawer }, children),
                    showMobileReactions && React.createElement(ReactionsMenu, { overflowMenu: true }))),
            showMobileReactions && React.createElement("div", { className: 'reactions-animations-container' }, reactionsQueue.map(({ reaction, uid }, index) => (React.createElement(ReactionEmoji, { index: index, key: uid, reaction: reaction, uid: uid }))))))) : (React.createElement(Popover, { content: children, onPopoverClose: onCloseDialog, onPopoverOpen: onOpenDialog, position: 'top', trigger: 'click', visible: isOpen },
        React.createElement(OverflowToggleButton, { isOpen: isOpen, onKeyDown: onEscClick })))));
};
export default OverflowMenuButton;
