/* eslint-disable lines-around-comment */
import React, { useEffect, useState } from 'react';
import { setPassword as setPass } from '../../../../base/conference/actions';
import { getSecurityUiConfig } from '../../../../base/config/functions.any';
import { isLocalParticipantModerator } from '../../../../base/participants/functions';
import { connect } from '../../../../base/redux/functions';
import Dialog from '../../../../base/ui/components/web/Dialog';
// @ts-ignore
import { E2EESection } from '../../../../e2ee/components';
// @ts-ignore
import { LobbySection } from '../../../../lobby';
/**
 * Component that renders the security options dialog.
 *
 * @returns {React$Element<any>}
 */
function SecurityDialog({ _buttonsWithNotifyClick, _canEditPassword, _conference, _disableLobbyPassword, _locked, _password, _passwordNumberOfDigits, _showE2ee, setPassword }) {
    const [passwordEditEnabled, setPasswordEditEnabled] = useState(false);
    useEffect(() => {
        if (passwordEditEnabled && _password) {
            setPasswordEditEnabled(false);
        }
    }, [_password]);
    return (React.createElement(Dialog, { cancel: { hidden: true }, ok: { hidden: true }, titleKey: 'security.title' },
        React.createElement("div", { className: 'security-dialog' },
            React.createElement(LobbySection, null),
            !_disableLobbyPassword && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'separator-line' }))),
            _showE2ee ? React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'separator-line' }),
                React.createElement(E2EESection, null)) : null)));
}
/**
 * Maps (parts of) the Redux state to the associated props for the
 * {@code SecurityDialog} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {IProps}
 */
function mapStateToProps(state) {
    const { conference, e2eeSupported, locked, password } = state['features/base/conference'];
    const { roomPasswordNumberOfDigits, buttonsWithNotifyClick } = state['features/base/config'];
    const { disableLobbyPassword } = getSecurityUiConfig(state);
    const showE2ee = Boolean(e2eeSupported) && isLocalParticipantModerator(state);
    return {
        _buttonsWithNotifyClick: buttonsWithNotifyClick,
        _canEditPassword: isLocalParticipantModerator(state),
        _conference: conference,
        _dialIn: state['features/invite'],
        _disableLobbyPassword: disableLobbyPassword,
        _locked: locked,
        _password: password,
        _passwordNumberOfDigits: roomPasswordNumberOfDigits,
        _showE2ee: showE2ee
    };
}
const mapDispatchToProps = { setPassword: setPass };
export default connect(mapStateToProps, mapDispatchToProps)(SecurityDialog);
