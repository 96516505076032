import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import Dialog from '../../../base/ui/components/web/Dialog';
/**
 * Creates the styles for the component.
 *
 * @param {Object} theme - The current UI theme.
 *
 * @returns {Object}
 */
const useStyles = makeStyles()(theme => {
    return {
        list: {
            listStyleType: 'none',
            padding: 0,
            '& .shortcuts-list__item': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: `${theme.spacing(1)} 0`,
                ...withPixelLineHeight(theme.typography.bodyShortRegular),
                color: theme.palette.text01
            },
            '& .item-action': {
                backgroundColor: theme.palette.ui04,
                ...withPixelLineHeight(theme.typography.labelBold),
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                borderRadius: `${Number(theme.shape.borderRadius) / 2}px`
            }
        }
    };
});
const KeyboardShortcutsDialog = ({ shortcutDescriptions }) => {
    const { classes, cx } = useStyles();
    const { t } = useTranslation();
    // eslint-disable-next-line react/no-multi-comp
    const _renderShortcutsListItem = (keyboardKey, translationKey) => {
        let modifierKey = 'Alt';
        if (window.navigator?.platform) {
            if (window.navigator.platform.indexOf('Mac') !== -1) {
                modifierKey = '⌥';
            }
        }
        return (React.createElement("li", { className: 'shortcuts-list__item', key: keyboardKey },
            React.createElement("span", { "aria-label": t(translationKey), className: 'shortcuts-list__description' }, t(translationKey)),
            React.createElement("span", { className: 'item-action' }, keyboardKey.startsWith(':')
                ? `${modifierKey} + ${keyboardKey.slice(1)}`
                : keyboardKey)));
    };
    return (React.createElement(Dialog, { cancel: { hidden: true }, ok: { hidden: true }, titleKey: 'keyboardShortcuts.keyboardShortcuts' },
        React.createElement("div", { id: 'keyboard-shortcuts' },
            React.createElement("ul", { className: cx('shortcuts-list', classes.list), id: 'keyboard-shortcuts-list' }, Array.from(shortcutDescriptions)
                .map(description => _renderShortcutsListItem(...description))))));
};
export default KeyboardShortcutsDialog;
