/* eslint-disable lines-around-comment */
import React from 'react';
// @ts-ignore
import { IconModerator } from '../../../base/icons';
// @ts-ignore
import { BaseIndicator } from '../../../base/react';
/**
 * React {@code Component} for showing a moderator icon with a tooltip.
 *
 * @returns {JSX.Element}
 */
const ModeratorIndicator = ({ tooltipPosition }) => (React.createElement(BaseIndicator, { icon: IconModerator, iconSize: 16, tooltipKey: 'videothumbnail.moderator', tooltipPosition: tooltipPosition }));
export default ModeratorIndicator;
