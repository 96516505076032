/* eslint-disable lines-around-comment */
import Modal, { ModalFooter } from '@atlaskit/modal-dialog';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { translate } from '../../../i18n/functions';
import Button from '../../../ui/components/web/Button';
import { BUTTON_TYPES } from '../../../ui/constants.web';
import ModalHeader from './ModalHeader';
/**
 * The ID to be used for the cancel button if enabled.
 *
 * @type {string}
 */
const CANCEL_BUTTON_ID = 'modal-dialog-cancel-button';
/**
 * The ID to be used for the ok button if enabled.
 *
 * @type {string}
 */
const OK_BUTTON_ID = 'modal-dialog-ok-button';
/**
 * Creates the styles for the component.
 *
 * @param {Object} theme - The theme.
 * @returns {Object}
 */
const styles = (theme) => {
    return {
        footer: {
            boxShadow: 'none'
        },
        buttonContainer: {
            display: 'flex',
            '& > button:first-child': {
                marginRight: theme.spacing(2)
            }
        }
    };
};
/**
 * Web dialog that uses atlaskit modal-dialog to display dialogs.
 */
class StatelessDialog extends Component {
    /**
     * Initializes a new {@code StatelessDialog} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        // Bind event handlers so they are only bound once for every instance.
        this._onCancel = this._onCancel.bind(this);
        this._onDialogDismissed = this._onDialogDismissed.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._renderFooter = this._renderFooter.bind(this);
        this._onDialogRef = this._onDialogRef.bind(this);
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { customHeader, children, hideCloseIconButton, t, titleString, titleKey, width } = this.props;
        return (React.createElement(Modal, { autoFocus: true, components: {
                // @ts-ignore
                Header: customHeader ? customHeader : props => (
                // @ts-ignore
                React.createElement(ModalHeader, { ...props, heading: titleString || t(titleKey ?? ''), hideCloseIconButton: hideCloseIconButton }))
            }, footer: this._renderFooter, i18n: this.props.i18n, onClose: this._onDialogDismissed, onDialogDismissed: this._onDialogDismissed, shouldCloseOnEscapePress: true, width: width || 'medium' },
            React.createElement("div", { onKeyPress: this._onKeyPress, ref: this._onDialogRef },
                React.createElement("form", { className: 'modal-dialog-form', id: 'modal-dialog-form', onSubmit: this._onSubmit }, children))));
    }
    /**
     * Returns a ReactElement to display buttons for closing the modal.
     *
     * @param {Object} propsFromModalFooter - The props passed in from the
     * {@link ModalFooter} component.
     * @private
     * @returns {ReactElement}
     */
    _renderFooter(propsFromModalFooter) {
        // Filter out falsy (null) values because {@code ButtonGroup} will error
        // if passed in anything but buttons with valid type props.
        const buttons = [
            this._renderCancelButton(),
            this._renderOKButton()
        ].filter(Boolean);
        if (this.props.disableFooter) {
            return null;
        }
        return (React.createElement(ModalFooter, { className: this.props.classes.footer, showKeyline: propsFromModalFooter.showKeyline },
            React.createElement("span", null),
            React.createElement("div", { className: this.props.classes.buttonContainer }, buttons)));
    }
    /**
     * Dispatches action to hide the dialog.
     *
     * @returns {void}
     */
    _onCancel() {
        if (!this.props.isModal) {
            const { onCancel } = this.props;
            onCancel?.();
        }
    }
    /**
     * Handles click on the blanket area.
     *
     * @returns {void}
     */
    _onDialogDismissed() {
        if (!this.props.disableBlanketClickDismiss) {
            this._onCancel();
        }
    }
    /**
     * Dispatches the action when submitting the dialog.
     *
     * @private
     * @param {string} value - The submitted value if any.
     * @returns {void}
     */
    _onSubmit(value) {
        const { onSubmit } = this.props;
        onSubmit?.(value);
    }
    /**
     * Renders Cancel button.
     *
     * @private
     * @returns {ReactElement|null} The Cancel button if enabled and dialog is
     * not modal.
     */
    _renderCancelButton() {
        if (this.props.cancelDisabled
            || this.props.isModal
            || this.props.hideCancelButton) {
            return null;
        }
        const { t, onDecline } = this.props;
        return (React.createElement(Button, { accessibilityLabel: t(this.props.cancelKey || 'dialog.Cancel'), id: CANCEL_BUTTON_ID, key: CANCEL_BUTTON_ID, label: t(this.props.cancelKey || 'dialog.Cancel'), onClick: onDecline || this._onCancel, size: 'small', type: BUTTON_TYPES.TERTIARY }));
    }
    /**
     * Renders OK button.
     *
     * @private
     * @returns {ReactElement|null} The OK button if enabled.
     */
    _renderOKButton() {
        const { submitDisabled, t } = this.props;
        if (submitDisabled) {
            return null;
        }
        return (React.createElement(Button, { accessibilityLabel: t(this.props.okKey || 'dialog.Ok'), disabled: this.props.okDisabled, id: OK_BUTTON_ID, key: OK_BUTTON_ID, label: t(this.props.okKey || 'dialog.Ok'), onClick: this._onSubmit, size: 'small' }));
    }
    /**
     * Callback invoked when setting the ref of the dialog's child passing the Modal ref.
     * It is done this way because we cannot directly access the ref of the Modal component.
     *
     * @param {HTMLElement} element - The DOM element for the dialog.
     * @private
     * @returns {void}
     */
    _onDialogRef(element) {
        this.props.onDialogRef?.(element?.parentNode);
    }
    /**
     * Handles 'Enter' key in the dialog to submit/hide dialog depending on
     * the available buttons and their disabled state.
     *
     * @param {Object} event - The key event.
     * @private
     * @returns {void}
     */
    _onKeyPress(event) {
        // If the event coming to the dialog has been subject to preventDefault
        // we don't handle it here.
        if (event.defaultPrevented) {
            return;
        }
        if (event.key === 'Enter' && !this.props.disableEnter) {
            event.preventDefault();
            event.stopPropagation();
            if (this.props.submitDisabled && !this.props.cancelDisabled) {
                this._onCancel();
            }
            else if (!this.props.okDisabled) {
                this._onSubmit();
            }
        }
    }
}
StatelessDialog.defaultProps = {
    hideCloseIconButton: false
};
export default translate(withStyles(styles)(StatelessDialog));
