import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { DRAWER_MAX_HEIGHT } from '../../constants';
const useStyles = makeStyles()(theme => {
    return {
        drawer: {
            backgroundColor: theme.palette.ui01,
            maxHeight: `calc(${DRAWER_MAX_HEIGHT})`,
            borderRadius: '24px 24px 0 0'
        }
    };
});
/**
 * Component that displays the mobile friendly drawer on web.
 *
 * @returns {ReactElement}
 */
function Drawer({ children, className = '', isOpen, onClose }) {
    const { classes: styles } = useStyles();
    /**
     * Handles clicks within the menu, preventing the propagation of the click event.
     *
     * @param {Object} event - The click event.
     * @returns {void}
     */
    const handleInsideClick = useCallback(event => {
        event.stopPropagation();
    }, []);
    /**
     * Handles clicks outside of the menu, closing it, and also stopping further propagation.
     *
     * @param {Object} event - The click event.
     * @returns {void}
     */
    const handleOutsideClick = useCallback(event => {
        event.stopPropagation();
        onClose?.();
    }, [onClose]);
    return (isOpen ? (React.createElement("div", { className: 'drawer-menu-container', onClick: handleOutsideClick },
        React.createElement("div", { className: `drawer-menu ${styles.drawer} ${className}`, onClick: handleInsideClick }, children))) : null);
}
export default Drawer;
