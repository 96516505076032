import { translate } from '../../base/i18n/functions';
import { IconNoiseSuppressionOff, IconNoiseSuppressionOn } from '../../base/icons/svg';
import { connect } from '../../base/redux/functions';
import { AbstractButton } from '../../base/toolbox/components';
import { setOverflowMenuVisible } from '../../toolbox/actions';
import { toggleNoiseSuppression } from '../actions';
import { isNoiseSuppressionEnabled } from '../functions';
/**
 * Component that renders a toolbar button for toggling noise suppression.
 */
class NoiseSuppressionButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.noiseSuppression';
        this.icon = IconNoiseSuppressionOn;
        this.label = 'toolbar.noiseSuppression';
        this.tooltip = 'toolbar.noiseSuppression';
        this.toggledIcon = IconNoiseSuppressionOff;
        this.toggledLabel = 'toolbar.disableNoiseSuppression';
    }
    /**
     * Handles clicking / pressing the button.
     *
     * @private
     * @returns {void}
     */
    _handleClick() {
        const { dispatch } = this.props;
        dispatch(toggleNoiseSuppression());
        dispatch(setOverflowMenuVisible(false));
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._isNoiseSuppressionEnabled;
    }
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {Props}
 */
function _mapStateToProps(state) {
    return {
        _isNoiseSuppressionEnabled: isNoiseSuppressionEnabled(state)
    };
}
// @ts-ignore
export default translate(connect(_mapStateToProps)(NoiseSuppressionButton));
