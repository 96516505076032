import InlineDialog from '@atlaskit/inline-dialog';
import React, { Component } from 'react';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import { Avatar } from '../../../base/avatar';
import { isNameReadOnly } from '../../../base/config/functions.web';
import { translate } from '../../../base/i18n/functions';
import { IconArrowDown, IconArrowUp, IconPhoneRinging, IconVolumeOff } from '../../../base/icons/svg';
import { isVideoMutedByUser } from '../../../base/media/functions';
import { getLocalParticipant } from '../../../base/participants/functions';
import ActionButton from '../../../base/premeeting/components/web/ActionButton';
import PreMeetingScreen from '../../../base/premeeting/components/web/PreMeetingScreen';
import { connect } from '../../../base/redux/functions';
import { updateSettings } from '../../../base/settings/actions';
import { getDisplayName } from '../../../base/settings/functions.web';
import { getLocalJitsiVideoTrack } from '../../../base/tracks/functions.web';
import Button from '../../../base/ui/components/web/Button';
import Input from '../../../base/ui/components/web/Input';
import { BUTTON_TYPES } from '../../../base/ui/constants.any';
import { joinConference as joinConferenceAction, joinConferenceWithoutAudio as joinConferenceWithoutAudioAction, setJoinByPhoneDialogVisiblity as setJoinByPhoneDialogVisiblityAction } from '../../actions.web';
import { isDeviceStatusVisible, isDisplayNameRequired, isJoinByPhoneButtonVisible, isJoinByPhoneDialogVisible, isPrejoinDisplayNameVisible } from '../../functions';
// @ts-ignore
import JoinByPhoneDialog from './dialogs/JoinByPhoneDialog';
/**
 * This component is displayed before joining a meeting.
 */
class Prejoin extends Component {
    /**
     * Initializes a new {@code Prejoin} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this.state = {
            showJoinByPhoneButtons: false
        };
        this._closeDialog = this._closeDialog.bind(this);
        this._showDialog = this._showDialog.bind(this);
        this._onJoinButtonClick = this._onJoinButtonClick.bind(this);
        this._onDropdownClose = this._onDropdownClose.bind(this);
        this._onOptionsClick = this._onOptionsClick.bind(this);
        this._setName = this._setName.bind(this);
        this._onJoinConferenceWithoutAudioKeyPress = this._onJoinConferenceWithoutAudioKeyPress.bind(this);
        this._showDialogKeyPress = this._showDialogKeyPress.bind(this);
        this._getExtraJoinButtons = this._getExtraJoinButtons.bind(this);
        this._onInputKeyPress = this._onInputKeyPress.bind(this);
        this.showDisplayNameField = props.canEditDisplayName || props.showErrorOnJoin;
    }
    /**
     * Handler for the join button.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    _onJoinButtonClick() {
        if (this.props.showErrorOnJoin) {
            return;
        }
        this.props.joinConference();
    }
    /**
     * Closes the dropdown.
     *
     * @returns {void}
     */
    _onDropdownClose() {
        this.setState({
            showJoinByPhoneButtons: false
        });
    }
    /**
     * Displays the join by phone buttons dropdown.
     *
     * @param {Object} e - The synthetic event.
     * @returns {void}
     */
    _onOptionsClick(e) {
        e?.stopPropagation();
        this.setState({
            showJoinByPhoneButtons: !this.state.showJoinByPhoneButtons
        });
    }
    /**
     * Sets the guest participant name.
     *
     * @param {string} displayName - Participant name.
     * @returns {void}
     */
    _setName(displayName) {
        this.props.updateSettings({
            displayName
        });
    }
    /**
     * Closes the join by phone dialog.
     *
     * @returns {undefined}
     */
    _closeDialog() {
        this.props.setJoinByPhoneDialogVisiblity(false);
    }
    /**
     * Displays the dialog for joining a meeting by phone.
     *
     * @returns {undefined}
     */
    _showDialog() {
        this.props.setJoinByPhoneDialogVisiblity(true);
        this._onDropdownClose();
    }
    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _showDialogKeyPress(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            this._showDialog();
        }
    }
    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onJoinConferenceWithoutAudioKeyPress(e) {
        if (this.props.joinConferenceWithoutAudio
            && (e.key === ' '
                || e.key === 'Enter')) {
            e.preventDefault();
            this.props.joinConferenceWithoutAudio();
        }
    }
    /**
     * Gets the list of extra join buttons.
     *
     * @returns {Object} - The list of extra buttons.
     */
    _getExtraJoinButtons() {
        const { joinConferenceWithoutAudio, t } = this.props;
        const noAudio = {
            key: 'no-audio',
            testId: 'prejoin.joinWithoutAudio',
            icon: IconVolumeOff,
            label: t('prejoin.joinWithoutAudio'),
            onClick: joinConferenceWithoutAudio,
            onKeyPress: this._onJoinConferenceWithoutAudioKeyPress
        };
        const byPhone = {
            key: 'by-phone',
            testId: 'prejoin.joinByPhone',
            icon: IconPhoneRinging,
            label: t('prejoin.joinAudioByPhone'),
            onClick: this._showDialog,
            onKeyPress: this._showDialogKeyPress
        };
        return {
            noAudio,
            byPhone
        };
    }
    /**
     * Handle keypress on input.
     *
     * @param {KeyboardEvent} e - Keyboard event.
     * @returns {void}
     */
    _onInputKeyPress(e) {
        const { joinConference } = this.props;
        if (e.key === 'Enter') {
            joinConference();
        }
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { deviceStatusVisible, hasJoinByPhoneButton, joinConferenceWithoutAudio, joiningInProgress, name, participantId, prejoinConfig, readOnlyName, showCameraPreview, showDialog, showErrorOnJoin, t, videoTrack } = this.props;
        const { _closeDialog, _onDropdownClose, _onJoinButtonClick, _onOptionsClick, _setName, _onInputKeyPress } = this;
        const extraJoinButtons = this._getExtraJoinButtons();
        let extraButtonsToRender = Object.values(extraJoinButtons).filter((val) => !(prejoinConfig?.hideExtraJoinButtons || []).includes(val.key));
        if (!hasJoinByPhoneButton) {
            extraButtonsToRender = extraButtonsToRender.filter((btn) => btn.key !== 'by-phone');
        }
        const hasExtraJoinButtons = Boolean(extraButtonsToRender.length);
        const { showJoinByPhoneButtons } = this.state;
        return (React.createElement(PreMeetingScreen, { showDeviceStatus: deviceStatusVisible, title: t('prejoin.joinMeeting'), videoMuted: !showCameraPreview, videoTrack: videoTrack },
            React.createElement("div", { className: 'prejoin-input-area', "data-testid": 'prejoin.screen' },
                this.showDisplayNameField ? (React.createElement(Input, { autoComplete: 'name', autoFocus: true, className: 'prejoin-input', error: showErrorOnJoin, onChange: _setName, onKeyPress: _onInputKeyPress, placeholder: t('dialog.enterDisplayName'), readOnly: readOnlyName, value: name })) : (React.createElement("div", { className: 'prejoin-avatar-container' },
                    React.createElement(Avatar, { className: 'prejoin-avatar', displayName: name, participantId: participantId, size: 72 }),
                    React.createElement("div", { className: 'prejoin-avatar-name' }, name))),
                showErrorOnJoin && React.createElement("div", { className: 'prejoin-error', "data-testid": 'prejoin.errorMessage' }, t('prejoin.errorMissingName')),
                React.createElement("div", { className: 'prejoin-preview-dropdown-container' },
                    React.createElement(InlineDialog, { content: hasExtraJoinButtons && React.createElement("div", { className: 'prejoin-preview-dropdown-btns' }, extraButtonsToRender.map(({ key, ...rest }) => (React.createElement(Button, { disabled: joiningInProgress, fullWidth: true, key: key, type: BUTTON_TYPES.SECONDARY, ...rest })))), isOpen: showJoinByPhoneButtons, onClose: _onDropdownClose },
                        React.createElement(ActionButton, { OptionsIcon: showJoinByPhoneButtons ? IconArrowUp : IconArrowDown, ariaDropDownLabel: t('prejoin.joinWithoutAudio'), ariaLabel: t('prejoin.joinMeeting'), ariaPressed: showJoinByPhoneButtons, disabled: joiningInProgress, hasOptions: hasExtraJoinButtons, onClick: _onJoinButtonClick, onOptionsClick: _onOptionsClick, role: 'button', tabIndex: 0, testId: 'prejoin.joinMeeting', type: 'primary' }, t('prejoin.joinMeeting'))))),
            showDialog && (React.createElement(JoinByPhoneDialog, { joinConferenceWithoutAudio: joinConferenceWithoutAudio, onClose: _closeDialog }))));
    }
}
/**
 * Maps (parts of) the redux state to the React {@code Component} props.
 *
 * @param {Object} state - The redux state.
 * @returns {Object}
 */
function mapStateToProps(state) {
    const name = getDisplayName(state);
    const showErrorOnJoin = isDisplayNameRequired(state) && !name;
    const { id: participantId } = getLocalParticipant(state) ?? {};
    const { joiningInProgress } = state['features/prejoin'];
    return {
        canEditDisplayName: isPrejoinDisplayNameVisible(state),
        deviceStatusVisible: isDeviceStatusVisible(state),
        hasJoinByPhoneButton: isJoinByPhoneButtonVisible(state),
        joiningInProgress,
        name,
        participantId,
        prejoinConfig: state['features/base/config'].prejoinConfig,
        readOnlyName: isNameReadOnly(state),
        showCameraPreview: !isVideoMutedByUser(state),
        showDialog: isJoinByPhoneDialogVisible(state),
        showErrorOnJoin,
        videoTrack: getLocalJitsiVideoTrack(state)
    };
}
const mapDispatchToProps = {
    joinConferenceWithoutAudio: joinConferenceWithoutAudioAction,
    joinConference: joinConferenceAction,
    setJoinByPhoneDialogVisiblity: setJoinByPhoneDialogVisiblityAction,
    updateSettings
};
export default connect(mapStateToProps, mapDispatchToProps)(translate(Prejoin));
