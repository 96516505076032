// @flow

import { IconHangup } from '../../icons';

import AbstractButton from './AbstractButton';
import type { Props } from './AbstractButton';

/**
 * An abstract implementation of a button for disconnecting a conference.
 */
export default class AbstractHangupButton<P : Props, S: *>
    extends AbstractButton<P, S> {

    icon = IconHangup;

    /**
     * Handles clicking / pressing the button, and disconnects the conference.
     *
     * @protected
     * @returns {void}
     */
    _handleClick() {
        //this._doHangup();
        let l = window.location.href.replace(interfaceConfig.WEB_URL + "/", "");
        let url_data = l.split("drd6p3r14sws9gk");
        if(url_data.length >= 1){
            if(url_data[1] && url_data[1].includes('b3jn')){
                window.parent.postMessage('meetingEndButton', '*');
            } else {
                window.parent.postMessage('meetingEndButtonClient', '*');
            }
        }
    }

    /**
     * Helper function to perform the actual hangup action.
     *
     * @protected
     * @returns {void}
     */
    _doHangup() {
        // To be implemented by subclass.
    }
}
