/**
 * Maps (parts of) the redux state to the associated props of this {@code Component}.
 *
 * @param {Object} state - The redux state.
 * @private
 * @returns {IProps}
 */
export function _mapStateToProps(state) {
    const { e2ee = {} } = state['features/base/config'];
    return {
        _e2eeLabels: e2ee.labels,
        _showLabel: state['features/e2ee'].everyoneEnabledE2EE
    };
}
