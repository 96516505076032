import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n/functions';
import { IconArrowUp } from '../../../base/icons/svg';
import { connect } from '../../../base/redux/functions';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import ToolboxButtonWithIconPopup from '../../../base/toolbox/components/web/ToolboxButtonWithIconPopup';
import { toggleReactionsMenuVisibility } from '../../actions.web';
import { getReactionsQueue, isReactionsEnabled } from '../../functions.any';
import { getReactionsMenuVisibility } from '../../functions.web';
// @ts-ignore
import RaiseHandButton from './RaiseHandButton';
import ReactionEmoji from './ReactionEmoji';
import ReactionsMenu from './ReactionsMenu';
/**
 * Button used for the reactions menu.
 *
 * @returns {ReactElement}
 */
function ReactionsMenuButton({ _reactionsEnabled, buttonKey, dispatch, handleClick, isOpen, isNarrow, notifyMode, reactionsQueue, t }) {
    const visible = useSelector(getReactionsMenuVisibility);
    const toggleReactionsMenu = useCallback(() => {
        dispatch(toggleReactionsMenuVisibility());
    }, [dispatch]);
    const openReactionsMenu = useCallback(() => {
        !visible && toggleReactionsMenu();
    }, [visible, toggleReactionsMenu]);
    const reactionsMenu = (React.createElement("div", { className: 'reactions-menu-container' },
        React.createElement(ReactionsMenu, null)));
    return (React.createElement("div", { className: 'reactions-menu-popup-container' },
        !_reactionsEnabled || isNarrow ? (React.createElement(RaiseHandButton, { buttonKey: buttonKey, handleClick: handleClick, notifyMode: notifyMode }))
            : (React.createElement(ToolboxButtonWithIconPopup, { ariaControls: 'reactions-menu-dialog', ariaExpanded: isOpen, ariaHasPopup: true, ariaLabel: t('toolbar.accessibilityLabel.reactionsMenu'), icon: IconArrowUp, iconDisabled: false, iconId: 'reactions-menu-button', onPopoverClose: toggleReactionsMenu, onPopoverOpen: openReactionsMenu, popoverContent: reactionsMenu, visible: visible },
                React.createElement(RaiseHandButton, { buttonKey: buttonKey, handleClick: handleClick, notifyMode: notifyMode }))),
        reactionsQueue.map(({ reaction, uid }, index) => (React.createElement(ReactionEmoji, { index: index, key: uid, reaction: reaction, uid: uid })))));
}
/**
 * Function that maps parts of Redux state tree into component props.
 *
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
function mapStateToProps(state) {
    const { isNarrowLayout } = state['features/base/responsive-ui'];
    return {
        _reactionsEnabled: isReactionsEnabled(state),
        isOpen: getReactionsMenuVisibility(state),
        isNarrow: isMobileBrowser() || isNarrowLayout,
        reactionsQueue: getReactionsQueue(state)
    };
}
export default translate(connect(mapStateToProps)(ReactionsMenuButton));
