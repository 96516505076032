import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import { isMobileBrowser } from '../../base/environment/utils';
import { translate } from '../../base/i18n/functions';
import ContextMenu from '../../base/ui/components/web/ContextMenu';
/**
 * Click handler.
 *
 * @param {SyntheticEvent} event - The click event.
 * @returns {void}
 */
function onClick(event) {
    // If the event is propagated to the thumbnail container the participant will be pinned. That's why the propagation
    // needs to be stopped.
    event.stopPropagation();
}
const styles = (theme) => {
    return {
        actions: {
            margin: '10px auto',
            textAlign: 'center'
        },
        connectionStatsTable: {
            '&, & > table': {
                fontSize: '12px',
                fontWeight: '400',
                '& td': {
                    padding: '2px 0'
                }
            },
            '& > table': {
                whiteSpace: 'nowrap'
            },
            '& td:nth-child(n-1)': {
                paddingLeft: '5px'
            },
            '& $upload, & $download': {
                marginRight: '2px'
            }
        },
        contextMenu: {
            position: 'relative',
            marginTop: 0,
            right: 'auto',
            padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        download: {},
        mobile: {
            margin: theme.spacing(3)
        },
        status: {
            fontWeight: 'bold'
        },
        upload: {}
    };
};
/**
 * React {@code Component} for displaying connection statistics.
 *
 * @augments Component
 */
class ConnectionStatsTable extends Component {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { classes, disableShowMoreStats, enableSaveLogs, isVirtualScreenshareParticipant, isLocalVideo, isNarrowLayout } = this.props;
        const className = clsx(classes.connectionStatsTable, { [classes.mobile]: isMobileBrowser() || isNarrowLayout });
        if (isVirtualScreenshareParticipant) {
            return this._renderScreenShareStatus();
        }
        return (React.createElement(ContextMenu, { className: classes.contextMenu, hidden: false, inDrawer: true },
            React.createElement("div", { className: className, onClick: onClick },
                this._renderStatistics(),
                React.createElement("div", { className: classes.actions },
                    isLocalVideo && enableSaveLogs ? this._renderSaveLogs() : null,
                    !disableShowMoreStats && this._renderShowMoreLink()),
                this.props.shouldShowMore ? this._renderAdditionalStats() : null)));
    }
    /**
     * Creates a ReactElement that will display connection statistics for a screen share thumbnail.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderScreenShareStatus() {
        const { classes } = this.props;
        const className = clsx(classes.connectionStatsTable, { [classes.mobile]: isMobileBrowser() });
        return (React.createElement(ContextMenu, { className: classes.contextMenu, hidden: false, inDrawer: true },
            React.createElement("div", { className: className, onClick: onClick },
                React.createElement("tbody", null,
                    this._renderResolution(),
                    this._renderFrameRate()))));
    }
    /**
     * Creates a table as ReactElement that will display additional statistics
     * related to bandwidth and transport for the local user.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderAdditionalStats() {
        const { isLocalVideo } = this.props;
        return (React.createElement("table", null,
            React.createElement("tbody", null,
                isLocalVideo ? this._renderBandwidth() : null,
                isLocalVideo ? this._renderTransport() : null,
                this._renderRegion(),
                isLocalVideo ? this._renderBridgeCount() : null,
                this._renderAudioSsrc(),
                this._renderVideoSsrc(),
                this._renderParticipantId())));
    }
    /**
     * Creates a table row as a ReactElement for displaying bandwidth related
     * statistics.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderBandwidth() {
        const { classes } = this.props;
        const { download, upload } = this.props.bandwidth || {};
        return (React.createElement("tr", null,
            React.createElement("td", null, this.props.t('connectionindicator.bandwidth')),
            React.createElement("td", null,
                React.createElement("span", { className: classes.download }, "\u2193"),
                download ? `${download} Kbps` : 'N/A',
                React.createElement("span", { className: classes.upload }, "\u2191"),
                upload ? `${upload} Kbps` : 'N/A')));
    }
    /**
     * Creates a a table row as a ReactElement for displaying bitrate related
     * statistics.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderBitrate() {
        const { classes } = this.props;
        const { download, upload } = this.props.bitrate || {};
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, this.props.t('connectionindicator.bitrate'))),
            React.createElement("td", null,
                React.createElement("span", { className: classes.download }, "\u2193"),
                download ? `${download} Kbps` : 'N/A',
                React.createElement("span", { className: classes.upload }, "\u2191"),
                upload ? `${upload} Kbps` : 'N/A')));
    }
    /**
     * Creates a table row as a ReactElement for displaying the audio ssrc.
     * This will typically be something like "Audio SSRC: 12345".
     *
     * @returns {JSX.Element}
     * @private
     */
    _renderAudioSsrc() {
        const { audioSsrc, t } = this.props;
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.audio_ssrc'))),
            React.createElement("td", null, audioSsrc || 'N/A')));
    }
    /**
     * Creates a table row as a ReactElement for displaying the video ssrc.
     * This will typically be something like "Video SSRC: 12345".
     *
     * @returns {JSX.Element}
     * @private
     */
    _renderVideoSsrc() {
        const { videoSsrc, t } = this.props;
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.video_ssrc'))),
            React.createElement("td", null, videoSsrc || 'N/A')));
    }
    /**
     * Creates a table row as a ReactElement for displaying the endpoint id.
     * This will typically be something like "Endpoint id: 1e8fbg".
     *
     * @returns {JSX.Element}
     * @private
     */
    _renderParticipantId() {
        const { participantId, t } = this.props;
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.participant_id'))),
            React.createElement("td", null, participantId || 'N/A')));
    }
    /**
     * Creates a a table row as a ReactElement for displaying codec, if present.
     * This will typically be something like "Codecs (A/V): Opus, vp8".
     *
     * @private
     * @returns {ReactElement}
     */
    _renderCodecs() {
        const { audioSsrc, codec, t, videoSsrc } = this.props;
        let codecString = 'N/A';
        if (codec) {
            const audioCodec = codec[audioSsrc]?.audio;
            const videoCodec = codec[videoSsrc]?.video;
            if (audioCodec || videoCodec) {
                codecString = [audioCodec, videoCodec].filter(Boolean).join(', ');
            }
        }
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.codecs'))),
            React.createElement("td", null, codecString)));
    }
    /**
     * Creates a a table row as a ReactElement for displaying e2ee verication status, if present.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderE2EEVerified() {
        const { e2eeVerified, t } = this.props;
        if (e2eeVerified === undefined) {
            return;
        }
        const status = e2eeVerified ? '\u{2705}' : '\u{274C}';
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.e2eeVerified'))),
            React.createElement("td", null, status)));
    }
    /**
     * Creates a table row as a ReactElement for displaying a summary message
     * about the current connection status.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderConnectionSummary() {
        const { classes } = this.props;
        return (React.createElement("tr", { className: classes.status },
            React.createElement("td", null,
                React.createElement("span", null, this.props.t('connectionindicator.status'))),
            React.createElement("td", null, this.props.connectionSummary)));
    }
    /**
     * Creates a table row as a ReactElement for displaying the "connected to"
     * information.
     *
     * @returns {ReactElement}
     * @private
     */
    _renderRegion() {
        const { region, serverRegion, t } = this.props;
        let str = serverRegion;
        if (!serverRegion) {
            return;
        }
        if (region && serverRegion && region !== serverRegion) {
            str += ` from ${region}`;
        }
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.connectedTo'))),
            React.createElement("td", null, str)));
    }
    /**
     * Creates a table row as a ReactElement for displaying the "bridge count"
     * information.
     *
     * @returns {*}
     * @private
     */
    _renderBridgeCount() {
        const { bridgeCount, t } = this.props;
        // 0 is valid, but undefined/null/NaN aren't.
        if (!bridgeCount && bridgeCount !== 0) {
            return;
        }
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.bridgeCount'))),
            React.createElement("td", null, bridgeCount)));
    }
    /**
     * Creates a table row as a ReactElement for displaying frame rate related
     * statistics.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderFrameRate() {
        const { framerate, t, videoSsrc } = this.props;
        let frameRateString = 'N/A';
        if (framerate) {
            frameRateString = String(framerate[videoSsrc] ?? 'N/A');
        }
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.framerate'))),
            React.createElement("td", null, frameRateString)));
    }
    /**
     * Creates a tables row as a ReactElement for displaying packet loss related
     * statistics.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderPacketLoss() {
        const { classes, packetLoss, t } = this.props;
        let packetLossTableData;
        if (packetLoss) {
            const { download, upload } = packetLoss;
            packetLossTableData = (React.createElement("td", null,
                React.createElement("span", { className: classes.download }, "\u2193"),
                download === null ? 'N/A' : `${download}%`,
                React.createElement("span", { className: classes.upload }, "\u2191"),
                upload === null ? 'N/A' : `${upload}%`));
        }
        else {
            packetLossTableData = React.createElement("td", null, "N/A");
        }
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.packetloss'))),
            packetLossTableData));
    }
    /**
     * Creates a table row as a ReactElement for displaying resolution related
     * statistics.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderResolution() {
        const { isVirtualScreenshareParticipant, maxEnabledResolution, resolution, t, videoSsrc } = this.props;
        let resolutionString = 'N/A';
        if (resolution && videoSsrc) {
            const { width, height } = resolution[videoSsrc] ?? {};
            if (width && height) {
                resolutionString = `${width}x${height}`;
                if (maxEnabledResolution && maxEnabledResolution < 720 && !isVirtualScreenshareParticipant) {
                    const maxEnabledResolutionTitle = t('connectionindicator.maxEnabledResolution');
                    resolutionString += ` (${maxEnabledResolutionTitle} ${maxEnabledResolution}p)`;
                }
            }
        }
        return (React.createElement("tr", null,
            React.createElement("td", null,
                React.createElement("span", null, t('connectionindicator.resolution'))),
            React.createElement("td", null, resolutionString)));
    }
    /**
     * Creates a ReactElement for display a link to save the logs.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderSaveLogs() {
        return (React.createElement("span", null,
            React.createElement("a", { className: 'savelogs link', onClick: this.props.onSaveLogs, role: 'button', tabIndex: 0 }, this.props.t('connectionindicator.savelogs')),
            React.createElement("span", null, " | ")));
    }
    /**
     * Creates a ReactElement for display a link to toggle showing additional
     * statistics.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderShowMoreLink() {
        const translationKey = this.props.shouldShowMore
            ? 'connectionindicator.less'
            : 'connectionindicator.more';
        return (React.createElement("a", { className: 'showmore link', onClick: this.props.onShowMore, role: 'button', tabIndex: 0 }, this.props.t(translationKey)));
    }
    /**
     * Creates a table as a ReactElement for displaying connection statistics.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderStatistics() {
        return (React.createElement("table", null,
            React.createElement("tbody", null,
                this._renderConnectionSummary(),
                this._renderBitrate(),
                this._renderPacketLoss(),
                this._renderResolution(),
                this._renderFrameRate(),
                this._renderCodecs(),
                this._renderE2EEVerified())));
    }
    /**
     * Creates table rows as ReactElements for displaying transport related
     * statistics.
     *
     * @private
     * @returns {ReactElement[]}
     */
    _renderTransport() {
        const { t, transport } = this.props;
        if (!transport || transport.length === 0) {
            const NA = (React.createElement("tr", { key: 'address' },
                React.createElement("td", null,
                    React.createElement("span", null, t('connectionindicator.address'))),
                React.createElement("td", null, "N/A")));
            return [NA];
        }
        const data = {
            localIP: [],
            localPort: [],
            remoteIP: [],
            remotePort: [],
            transportType: []
        };
        for (let i = 0; i < transport.length; i++) {
            const ip = getIP(transport[i].ip);
            const localIP = getIP(transport[i].localip);
            const localPort = getPort(transport[i].localip);
            const port = getPort(transport[i].ip);
            if (!data.remoteIP.includes(ip)) {
                data.remoteIP.push(ip);
            }
            if (!data.localIP.includes(localIP)) {
                data.localIP.push(localIP);
            }
            if (!data.localPort.includes(localPort)) {
                data.localPort.push(localPort);
            }
            if (!data.remotePort.includes(port)) {
                data.remotePort.push(port);
            }
            if (!data.transportType.includes(transport[i].type)) {
                data.transportType.push(transport[i].type);
            }
        }
        // All of the transports should be either P2P or JVB
        let isP2P = false, isTURN = false;
        if (transport.length) {
            isP2P = transport[0].p2p;
            isTURN = transport[0].localCandidateType === 'relay'
                || transport[0].remoteCandidateType === 'relay';
        }
        const additionalData = [];
        if (isP2P) {
            additionalData.push(React.createElement("span", null, " (p2p)"));
        }
        if (isTURN) {
            additionalData.push(React.createElement("span", null, " (turn)"));
        }
        // First show remote statistics, then local, and then transport type.
        const tableRowConfigurations = [
            {
                additionalData,
                data: data.remoteIP,
                key: 'remoteaddress',
                label: t('connectionindicator.remoteaddress', { count: data.remoteIP.length })
            },
            {
                data: data.remotePort,
                key: 'remoteport',
                label: t('connectionindicator.remoteport', { count: transport.length })
            },
            {
                data: data.localIP,
                key: 'localaddress',
                label: t('connectionindicator.localaddress', { count: data.localIP.length })
            },
            {
                data: data.localPort,
                key: 'localport',
                label: t('connectionindicator.localport', { count: transport.length })
            },
            {
                data: data.transportType,
                key: 'transport',
                label: t('connectionindicator.transport', { count: data.transportType.length })
            }
        ];
        return tableRowConfigurations.map(this._renderTransportTableRow);
    }
    /**
     * Creates a table row as a ReactElement for displaying a transport related
     * statistic.
     *
     * @param {Object} config - Describes the contents of the row.
     * @param {ReactElement} config.additionalData - Extra data to display next
     * to the passed in config.data.
     * @param {Array} config.data - The transport statistics to display.
     * @param {string} config.key - The ReactElement's key. Must be unique for
     * iterating over multiple child rows.
     * @param {string} config.label - The text to display describing the data.
     * @private
     * @returns {ReactElement}
     */
    _renderTransportTableRow(config) {
        const { additionalData, data, key, label } = config;
        return (React.createElement("tr", { key: key },
            React.createElement("td", null,
                React.createElement("span", null, label)),
            React.createElement("td", null,
                getStringFromArray(data),
                additionalData || null)));
    }
}
/**
 * Utility for getting the IP from a transport statistics object's
 * representation of an IP.
 *
 * @param {string} value - The transport's IP to parse.
 * @private
 * @returns {string}
 */
function getIP(value) {
    if (!value) {
        return '';
    }
    return value.substring(0, value.lastIndexOf(':'));
}
/**
 * Utility for getting the port from a transport statistics object's
 * representation of an IP.
 *
 * @param {string} value - The transport's IP to parse.
 * @private
 * @returns {string}
 */
function getPort(value) {
    if (!value) {
        return '';
    }
    return value.substring(value.lastIndexOf(':') + 1, value.length);
}
/**
 * Utility for concatenating values in an array into a comma separated string.
 *
 * @param {Array} array - Transport statistics to concatenate.
 * @private
 * @returns {string}
 */
function getStringFromArray(array) {
    let res = '';
    for (let i = 0; i < array.length; i++) {
        res += (i === 0 ? '' : ', ') + array[i];
    }
    return res;
}
export default translate(withStyles(styles)(ConnectionStatsTable));
