export const CONFERENCE_INFO = {
    alwaysVisible: ['raised-hands-count', 'recording'],
    autoHide: [
        'highlight-moment',
        'subject',
        'conference-timer',
        'participants-count',
        'e2ee',
        'transcribing',
        'video-quality',
        'insecure-room',
        'top-panel-toggle'
    ]
};
