import React, { Component } from 'react';
import { translate } from '../../../base/i18n/functions';
import { connect } from '../../../base/redux/functions';
import { toggleScreensharing } from '../../../base/tracks/actions';
import Dialog from '../../../base/ui/components/web/Dialog';
/**
 *  Component that displays the share audio helper dialog.
 */
class ShareScreenWarningDialog extends Component {
    /**
     * Instantiates a new component.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._onStopSharing = this._onStopSharing.bind(this);
    }
    /**
     * Stop current screen sharing session.
     *
     * @returns {boolean}
     */
    _onStopSharing() {
        // Depending on the context from which this dialog is opened we'll either be toggling off an audio only
        // share session or a normal screen sharing one, this is indicated by the _isAudioScreenShareWarning prop.
        this.props.dispatch(toggleScreensharing(undefined, !this.props._isAudioScreenShareWarning));
        return true;
    }
    /**
     * Implements {@Component#render}.
     *§.
     *
     * @inheritdoc
     */
    render() {
        const { t } = this.props;
        let description1, description2, header1, header2, stopSharing, title;
        if (this.props._isAudioScreenShareWarning) {
            header1 = 'dialog.shareAudioWarningH1';
            header2 = 'dialog.shareMediaWarningGenericH2';
            description1 = 'dialog.shareAudioWarningD1';
            description2 = 'dialog.shareAudioWarningD2';
            title = 'dialog.shareAudioWarningTitle';
            stopSharing = 'toolbar.stopScreenSharing';
        }
        else {
            header1 = 'dialog.shareScreenWarningTitle';
            header2 = 'dialog.shareMediaWarningGenericH2';
            description1 = 'dialog.shareScreenWarningD1';
            description2 = 'dialog.shareScreenWarningD2';
            title = 'dialog.shareScreenWarningTitle';
            stopSharing = 'toolbar.stopAudioSharing';
        }
        return (React.createElement(Dialog, { ok: { translationKey: stopSharing }, onSubmit: this._onStopSharing, titleKey: t(title) },
            React.createElement("div", { className: 'share-screen-warn-dialog' },
                React.createElement("p", { className: 'header' },
                    " ",
                    t(header1),
                    " "),
                React.createElement("p", { className: 'description' },
                    " ",
                    t(description1),
                    " "),
                React.createElement("div", { className: 'separator-line' }),
                React.createElement("p", { className: 'header' },
                    " ",
                    t(header2),
                    " "),
                React.createElement("p", { className: 'description' },
                    " ",
                    t(description2),
                    " "))));
    }
}
export default translate(connect()(ShareScreenWarningDialog));
