import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ListItem from '../../../../../base/components/participants-pane-list/ListItem';
import Icon from '../../../../../base/icons/components/Icon';
import { IconArrowDown, IconArrowUp } from '../../../../../base/icons/svg';
import { isLocalParticipantModerator } from '../../../../../base/participants/functions';
import { withPixelLineHeight } from '../../../../../base/styles/functions.web';
import { showOverflowDrawer } from '../../../../../toolbox/functions.web';
import { ACTION_TRIGGER } from '../../../../constants';
import { participantMatchesSearch } from '../../../../functions';
import ParticipantActionEllipsis from '../../../web/ParticipantActionEllipsis';
import ParticipantItem from '../../../web/ParticipantItem';
const useStyles = makeStyles()(theme => {
    return {
        container: {
            boxShadow: 'none'
        },
        roomName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...withPixelLineHeight(theme.typography.labelButton),
            padding: '12px 0'
        },
        arrowContainer: {
            backgroundColor: theme.palette.ui03,
            width: '24px',
            height: '24px',
            borderRadius: '6px',
            marginRight: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    };
});
export const CollapsibleRoom = ({ actionsTrigger = ACTION_TRIGGER.HOVER, children, isHighlighted, onRaiseMenu, onLeave, participantContextEntity, raiseParticipantContextMenu, room, searchString, toggleParticipantMenu }) => {
    const { t } = useTranslation();
    const { classes: styles, cx } = useStyles();
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed]);
    const raiseMenu = useCallback(target => {
        onRaiseMenu(target);
    }, [onRaiseMenu]);
    const { defaultRemoteDisplayName } = useSelector((state) => state['features/base/config']);
    const overflowDrawer = useSelector(showOverflowDrawer);
    const moderator = useSelector(isLocalParticipantModerator);
    const arrow = (React.createElement("div", { className: styles.arrowContainer },
        React.createElement(Icon, { size: 14, src: collapsed ? IconArrowDown : IconArrowUp })));
    const roomName = (React.createElement("span", { className: styles.roomName }, `${room.name || t('breakoutRooms.mainRoom')} (${Object.keys(room?.participants
        || {}).length})`));
    const raiseParticipantMenu = useCallback(({ participantID, displayName }) => moderator
        && raiseParticipantContextMenu({
            room,
            jid: participantID,
            participantName: displayName
        }), [room, moderator]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { actions: children, className: cx(styles.container, 'breakout-room-container'), icon: arrow, isHighlighted: isHighlighted, onClick: toggleCollapsed, onLongPress: raiseMenu, onMouseLeave: onLeave, testId: room.id, textChildren: roomName, trigger: actionsTrigger }),
        !collapsed && room?.participants
            && Object.values(room?.participants || {}).map(p => participantMatchesSearch(p, searchString) && (React.createElement(ParticipantItem, { actionsTrigger: ACTION_TRIGGER.HOVER, displayName: p.displayName || defaultRemoteDisplayName, isHighlighted: participantContextEntity?.jid === p.jid, key: p.jid, local: false, openDrawerForParticipant: raiseParticipantMenu, overflowDrawer: overflowDrawer, participantID: p.jid }, !overflowDrawer && moderator && (React.createElement(ParticipantActionEllipsis, { accessibilityLabel: t('breakoutRoom.more'), onClick: toggleParticipantMenu({ room,
                    jid: p.jid,
                    participantName: p.displayName }) })))))));
};
