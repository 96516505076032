import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
// @ts-ignore
import { Avatar } from '../../../base/avatar';
import { IconModerator } from "../../../base/icons/svg";
import ListItem from '../../../base/components/participants-pane-list/ListItem';
import { translate } from '../../../base/i18n/functions';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import { ACTION_TRIGGER, AudioStateIcons, MEDIA_STATE, VideoStateIcons } from '../../constants';
import { RaisedHandIndicator } from './RaisedHandIndicator';
import Icon from '../../../base/icons/components/Icon';
const useStyles = makeStyles()(theme => {
    return {
        nameContainer: {
            display: 'flex',
            flex: 1,
            overflow: 'hidden'
        },
        name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        moderatorLabel: {
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text03
        }
    };
});
/**
 * A component representing a participant entry in ParticipantPane and Lobby.
 *
 * @param {IProps} props - The props of the component.
 * @returns {ReactNode}
 */
function ParticipantItem({ actionsTrigger = ACTION_TRIGGER.HOVER, audioMediaState = MEDIA_STATE.NONE, children, disableModeratorIndicator, displayName, isHighlighted, isModerator, local, onLeave, openDrawerForParticipant, overflowDrawer, participantID, raisedHand, t, videoMediaState = MEDIA_STATE.NONE, youText }) {
    const onClick = useCallback(() => openDrawerForParticipant?.({
        participantID,
        displayName
    }), []);
    const { classes: styles } = useStyles();
    const icon = (React.createElement(Avatar, { className: 'participant-avatar', displayName: displayName, participantId: participantID, size: 32 }));
    const text = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.nameContainer },
            React.createElement("div", { className: styles.name }, displayName),
            local ? React.createElement("span", null,
                "\u00A0(",
                youText,
                ")") : null),
        isModerator && React.createElement("div", { className: styles.moderatorLabel }, 'Organizer')));
    const indicators = (React.createElement(React.Fragment, null,
        isModerator &&
            React.createElement("div", { id: "modind" },
                React.createElement(Icon, { size: 15, src: IconModerator })),
        raisedHand && React.createElement(RaisedHandIndicator, null),
        VideoStateIcons[videoMediaState],
        AudioStateIcons[audioMediaState]));
    return (React.createElement(ListItem, { actions: children, hideActions: local, icon: icon, id: `participant-item-${participantID}`, indicators: indicators, isHighlighted: isHighlighted, onClick: !local && overflowDrawer ? onClick : undefined, onMouseLeave: onLeave, textChildren: text, trigger: actionsTrigger }));
}
export default translate(ParticipantItem);
