export const SETTINGS_TABS = {
    CALENDAR: 'calendar_tab',
    DEVICES: 'devices_tab',
    MORE: 'more_tab',
    MODERATOR: 'moderator-tab',
    PROFILE: 'profile_tab',
    SOUNDS: 'sounds_tab'
};
/**
 * Default frame rate to be used for capturing screenshare.
 */
export const SS_DEFAULT_FRAME_RATE = 5;
/**
 * Supported framerates to be used for capturing screenshare.
 */
export const SS_SUPPORTED_FRAMERATES = [5, 15, 30];
