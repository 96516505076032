import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createToolbarEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import { IconAddUser } from '../../../base/icons/svg';
import Button from '../../../base/ui/components/web/Button';
import { BUTTON_TYPES } from '../../../base/ui/constants.web';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import { beginAddPeople } from '../../../invite';
export const InviteButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onInvite = useCallback(() => {
        sendAnalytics(createToolbarEvent('invite'));
        dispatch(beginAddPeople());
    }, [dispatch]);
    return (React.createElement(Button, { accessibilityLabel: t('participantsPane.actions.invite'), fullWidth: true, icon: IconAddUser, labelKey: 'participantsPane.actions.invite', onClick: onInvite, type: BUTTON_TYPES.PRIMARY }));
};
