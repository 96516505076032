// @ts-ignore
import { translate } from '../../../base/i18n';
import { IconCloseLarge, IconHangup } from '../../../base/icons/svg';
// eslint-disable-next-line lines-around-comment
// @ts-ignore
import { AbstractButton } from '../../../base/toolbox/components';
/**
 * Implementation of a button for toggling the hangup menu.
 */
class HangupToggleButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.hangup';
        this.icon = IconHangup;
        this.label = 'toolbar.hangup';
        this.toggledIcon = IconCloseLarge;
        this.toggledLabel = 'toolbar.hangup';
    }
    /**
     * Retrieves tooltip dynamically.
     */
    get tooltip() {
        return 'toolbar.hangup';
    }
    /**
     * Required by linter due to AbstractButton overwritten prop being writable.
     *
     * @param {string} _value - The value.
     */
    set tooltip(_value) {
        // Unused.
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props.isOpen;
    }
    /**
     * Indicates whether a key was pressed.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _onKeyDown() {
        this.props.onKeyDown();
    }
}
export default translate(HangupToggleButton);
