import { PureComponent } from 'react';
/**
 * Implements an abstract stateless avatar component that renders an avatar purely from what gets passed through
 * props.
 */
export default class AbstractStatelessAvatar extends PureComponent {
    /**
     * Checks if the passed prop is a loaded icon or not.
     *
     * @param {string? | Object?} iconProp - The prop to check.
     * @returns {boolean}
     */
    _isIcon(iconProp) {
        return Boolean(iconProp) && (typeof iconProp === 'object' || typeof iconProp === 'function');
    }
}
