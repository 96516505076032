import { PureComponent } from 'react';
import { getParticipantDisplayName, isLocalParticipantModerator } from '../../base/participants/functions';
import { setLobbyChatActiveState } from '../actions.any';
import { setPrivateMessageRecipient } from '../actions.web';
/**
 * Abstract class for the {@code MessageRecipient} component.
 */
export default class AbstractMessageRecipient extends PureComponent {
}
/**
 * Maps part of the props of this component to Redux actions.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 * @returns {IProps}
 */
export function _mapDispatchToProps(dispatch) {
    return {
        _onRemovePrivateMessageRecipient: () => {
            dispatch(setPrivateMessageRecipient());
        },
        _onHideLobbyChatRecipient: () => {
            dispatch(setLobbyChatActiveState(false));
        }
    };
}
/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
export function _mapStateToProps(state) {
    const { privateMessageRecipient, lobbyMessageRecipient, isLobbyChatActive } = state['features/chat'];
    return {
        _privateMessageRecipient: privateMessageRecipient ? getParticipantDisplayName(state, privateMessageRecipient.id) : undefined,
        _isLobbyChatActive: isLobbyChatActive,
        _lobbyMessageRecipient: isLobbyChatActive && lobbyMessageRecipient ? lobbyMessageRecipient.name : undefined,
        _visible: isLobbyChatActive ? isLocalParticipantModerator(state) : true
    };
}
