import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../../base/i18n/functions';
import { IconMic, IconVolumeUp } from '../../../../base/icons/svg';
import JitsiMeetJS from '../../../../base/lib-jitsi-meet';
import { equals } from '../../../../base/redux/functions';
import Checkbox from '../../../../base/ui/components/web/Checkbox';
import ContextMenu from '../../../../base/ui/components/web/ContextMenu';
import ContextMenuItem from '../../../../base/ui/components/web/ContextMenuItem';
import ContextMenuItemGroup from '../../../../base/ui/components/web/ContextMenuItemGroup';
import { toggleNoiseSuppression } from '../../../../noise-suppression/actions';
import { isNoiseSuppressionEnabled } from '../../../../noise-suppression/functions';
import { isPrejoinPageVisible } from '../../../../prejoin/functions';
import { createLocalAudioTracks } from '../../../functions.web';
import MicrophoneEntry from './MicrophoneEntry';
import SpeakerEntry from './SpeakerEntry';
const browser = JitsiMeetJS.util.browser;
/**
 * Translates the default device label into a more user friendly one.
 *
 * @param {string} deviceId - The device Id.
 * @param {string} label - The device label.
 * @param {Function} t - The translation function.
 * @returns {string}
 */
function transformDefaultDeviceLabel(deviceId, label, t) {
    return deviceId === 'default'
        ? t('settings.sameAsSystem', { label: label.replace('Default - ', '') })
        : label;
}
/**
 * Implements a React {@link Component} which displays a list of all
 * the audio input & output devices to choose from.
 *
 * @augments Component
 */
class AudioSettingsContent extends Component {
    /**
     * Initializes a new {@code AudioSettingsContent} instance.
     *
     * @param {Object} props - The read-only properties with which the new
     * instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this.microphoneHeaderId = 'microphone_settings_header';
        this.speakerHeaderId = 'speaker_settings_header';
        this._onMicrophoneEntryClick = this._onMicrophoneEntryClick.bind(this);
        this._onSpeakerEntryClick = this._onSpeakerEntryClick.bind(this);
        this.state = {
            audioTracks: props.microphoneDevices.map(({ deviceId, label }) => {
                return {
                    deviceId,
                    hasError: false,
                    jitsiTrack: null,
                    label
                };
            })
        };
    }
    /**
     * Click handler for the microphone entries.
     *
     * @param {string} deviceId - The deviceId for the clicked microphone.
     * @returns {void}
     */
    _onMicrophoneEntryClick(deviceId) {
        this.props.setAudioInputDevice(deviceId);
    }
    /**
     * Click handler for the speaker entries.
     *
     * @param {string} deviceId - The deviceId for the clicked speaker.
     * @returns {void}
     */
    _onSpeakerEntryClick(deviceId) {
        this.props.setAudioOutputDevice(deviceId);
    }
    /**
     * Renders a single microphone entry.
     *
     * @param {Object} data - An object with the deviceId, jitsiTrack & label of the microphone.
     * @param {number} index - The index of the element, used for creating a key.
     * @param {length} length - The length of the microphone list.
     * @param {Function} t - The translation function.
     * @returns {React$Node}
     */
    _renderMicrophoneEntry(data, index, length, t) {
        const { deviceId, jitsiTrack, hasError } = data;
        const label = transformDefaultDeviceLabel(deviceId, data.label, t);
        const isSelected = deviceId === this.props.currentMicDeviceId;
        return (React.createElement(MicrophoneEntry, { deviceId: deviceId, hasError: hasError, index: index, isSelected: isSelected, jitsiTrack: jitsiTrack, key: `me-${index}`, length: length, listHeaderId: this.microphoneHeaderId, measureAudioLevels: this.props.measureAudioLevels, onClick: this._onMicrophoneEntryClick }, label));
    }
    /**
     * Renders a single speaker entry.
     *
     * @param {Object} data - An object with the deviceId and label of the speaker.
     * @param {number} index - The index of the element, used for creating a key.
     * @param {length} length - The length of the speaker list.
     * @param {Function} t - The translation function.
     * @returns {React$Node}
     */
    _renderSpeakerEntry(data, index, length, t) {
        const { deviceId } = data;
        const label = transformDefaultDeviceLabel(deviceId, data.label, t);
        const key = `se-${index}`;
        const isSelected = deviceId === this.props.currentOutputDeviceId;
        return (React.createElement(SpeakerEntry, { deviceId: deviceId, index: index, isSelected: isSelected, key: key, length: length, listHeaderId: this.speakerHeaderId, onClick: this._onSpeakerEntryClick }, label));
    }
    /**
     * Creates and updates the audio tracks.
     *
     * @returns {void}
     */
    async _setTracks() {
        if (browser.isWebKitBased()) {
            // It appears that at the time of this writing, creating audio tracks blocks the browser's main thread for
            // long time on safari. Wasn't able to confirm which part of track creation does the blocking exactly, but
            // not creating the tracks seems to help and makes the UI much more responsive.
            return;
        }
        this._disposeTracks(this.state.audioTracks);
        const audioTracks = await createLocalAudioTracks(this.props.microphoneDevices, 5000);
        if (this._componentWasUnmounted) {
            this._disposeTracks(audioTracks);
        }
        else {
            this.setState({
                audioTracks
            });
        }
    }
    /**
     * Disposes the audio tracks.
     *
     * @param {Object} audioTracks - The object holding the audio tracks.
     * @returns {void}
     */
    _disposeTracks(audioTracks) {
        audioTracks.forEach(({ jitsiTrack }) => {
            jitsiTrack?.dispose();
        });
    }
    /**
     * Implements React's {@link Component#componentDidMount}.
     *
     * @inheritdoc
     */
    componentDidMount() {
        this._setTracks();
    }
    /**
     * Implements React's {@link Component#componentWillUnmount}.
     *
     * @inheritdoc
     */
    componentWillUnmount() {
        this._componentWasUnmounted = true;
        this._disposeTracks(this.state.audioTracks);
    }
    /**
     * Implements React's {@link Component#componentDidUpdate}.
     *
     * @inheritdoc
     */
    componentDidUpdate(prevProps) {
        if (!equals(this.props.microphoneDevices, prevProps.microphoneDevices)) {
            this._setTracks();
        }
    }
    /**
     * Implements React's {@link Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { outputDevices, t, noiseSuppressionEnabled, toggleSuppression, prejoinVisible } = this.props;
        return (React.createElement(ContextMenu, { "aria-labelledby": 'audio-settings-button', className: 'audio-preview-content', hidden: false, id: 'audio-settings-dialog', tabIndex: -1 },
            React.createElement(ContextMenuItemGroup, null,
                React.createElement(ContextMenuItem, { accessibilityLabel: t('settings.microphones'), className: 'audio-preview-header', icon: IconMic, id: this.microphoneHeaderId, text: t('settings.microphones') }),
                React.createElement("ul", { "aria-labelledby": this.microphoneHeaderId, className: 'audio-preview-content-ul', role: 'radiogroup', tabIndex: -1 }, this.state.audioTracks.map((data, i) => this._renderMicrophoneEntry(data, i, this.state.audioTracks.length, t)))),
            outputDevices.length > 0 && (React.createElement(ContextMenuItemGroup, null,
                React.createElement(ContextMenuItem, { accessibilityLabel: t('settings.speakers'), className: 'audio-preview-header', icon: IconVolumeUp, id: this.speakerHeaderId, text: t('settings.speakers') }),
                React.createElement("ul", { "aria-labelledby": this.speakerHeaderId, className: 'audio-preview-content-ul', role: 'radiogroup', tabIndex: -1 }, outputDevices.map((data, i) => this._renderSpeakerEntry(data, i, outputDevices.length, t))))),
            !prejoinVisible && (React.createElement(ContextMenuItemGroup, null,
                React.createElement("div", { className: 'audio-preview-checkbox-container', 
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick: e => e.stopPropagation() },
                    React.createElement(Checkbox, { checked: noiseSuppressionEnabled, label: t('toolbar.noiseSuppression'), onChange: toggleSuppression }))))));
    }
}
const mapStateToProps = (state) => {
    return {
        noiseSuppressionEnabled: isNoiseSuppressionEnabled(state),
        prejoinVisible: isPrejoinPageVisible(state)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggleSuppression() {
            dispatch(toggleNoiseSuppression());
        }
    };
};
export default translate(connect(mapStateToProps, mapDispatchToProps)(AudioSettingsContent));
