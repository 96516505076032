import { GiphyFetch, setServerUrl } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { createGifSentEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import Input from '../../../base/ui/components/web/Input';
import { sendMessage } from '../../../chat/actions.any';
import { SCROLL_SIZE } from '../../../filmstrip/constants';
import { toggleReactionsMenuVisibility } from '../../../reactions/actions.web';
import { setOverflowMenuVisible } from '../../../toolbox/actions.web';
import Drawer from '../../../toolbox/components/web/Drawer';
import JitsiPortal from '../../../toolbox/components/web/JitsiPortal';
import { showOverflowDrawer } from '../../../toolbox/functions.web';
import { setGifDrawerVisibility } from '../../actions';
import { formatGifUrlMessage, getGifAPIKey, getGifRating, getGifUrl, getGiphyProxyUrl } from '../../function.any';
const OVERFLOW_DRAWER_PADDING = 16;
const useStyles = makeStyles()(theme => {
    return {
        gifsMenu: {
            width: '100%',
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            '& div:focus': {
                border: '1px solid red !important',
                boxSizing: 'border-box'
            }
        },
        searchField: {
            marginBottom: theme.spacing(3)
        },
        gifContainer: {
            height: '245px',
            overflowY: 'auto'
        },
        logoContainer: {
            width: `calc(100% - ${SCROLL_SIZE}px)`,
            backgroundColor: '#121119',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            marginTop: theme.spacing(1)
        },
        overflowMenu: {
            padding: theme.spacing(3),
            width: '100%',
            boxSizing: 'border-box'
        },
        gifContainerOverflow: {
            flexGrow: 1
        },
        drawer: {
            display: 'flex',
            height: '100%'
        }
    };
});
/**
 * Gifs menu.
 *
 * @returns {ReactElement}
 */
function GifsMenu() {
    const API_KEY = useSelector(getGifAPIKey);
    const giphyFetch = new GiphyFetch(API_KEY);
    const [searchKey, setSearchKey] = useState();
    const { classes: styles, cx } = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const overflowDrawer = useSelector(showOverflowDrawer);
    const { clientWidth } = useSelector((state) => state['features/base/responsive-ui']);
    const rating = useSelector(getGifRating);
    const proxyUrl = useSelector(getGiphyProxyUrl);
    const fetchGifs = useCallback(async (offset = 0) => {
        const options = {
            limit: 20,
            offset,
            rating
        };
        if (!searchKey) {
            return await giphyFetch.trending(options);
        }
        return await giphyFetch.search(searchKey, options);
    }, [searchKey]);
    const onDrawerClose = useCallback(() => {
        dispatch(setGifDrawerVisibility(false));
        dispatch(setOverflowMenuVisible(false));
    }, []);
    const handleGifClick = useCallback((gif, e) => {
        e?.stopPropagation();
        const url = getGifUrl(gif, proxyUrl);
        sendAnalytics(createGifSentEvent());
        batch(() => {
            dispatch(sendMessage(formatGifUrlMessage(url), true));
            dispatch(toggleReactionsMenuVisibility());
            overflowDrawer && onDrawerClose();
        });
    }, [dispatch, overflowDrawer]);
    const handleGifKeyPress = useCallback((gif, e) => {
        if (e.nativeEvent.keyCode === 13) {
            handleGifClick(gif, null);
        }
    }, [handleGifClick]);
    const handleSearchKeyChange = useCallback(value => {
        setSearchKey(value);
    }, []);
    const handleKeyDown = useCallback(e => {
        if (!document.activeElement) {
            return;
        }
        if (e.keyCode === 38) { // up arrow
            e.preventDefault();
            // if the first gif is focused move focus to the input
            if (document.activeElement.previousElementSibling === null) {
                const element = document.querySelector('.gif-input');
                element?.focus();
            }
            else {
                const element = document.activeElement.previousElementSibling;
                element?.focus();
            }
        }
        else if (e.keyCode === 40) { // down arrow
            e.preventDefault();
            // if the input is focused move focus to the first gif
            if (document.activeElement.classList.contains('gif-input')) {
                const element = document.querySelector('.giphy-gif');
                element?.focus();
            }
            else {
                const element = document.activeElement.nextElementSibling;
                element?.focus();
            }
        }
    }, []);
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);
    // For some reason, the Grid component does not do an initial call on mobile.
    // This fixes that.
    useEffect(() => setSearchKey(''), []);
    useEffect(() => {
        if (proxyUrl) {
            setServerUrl(proxyUrl);
        }
    }, []);
    const onInputKeyPress = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const gifMenu = (React.createElement("div", { className: cx(styles.gifsMenu, overflowDrawer && styles.overflowMenu) },
        React.createElement(Input, { autoFocus: true, className: cx(styles.searchField, 'gif-input'), onChange: handleSearchKeyChange, onKeyPress: onInputKeyPress, placeholder: t('giphy.search'), 
            // eslint-disable-next-line react/jsx-no-bind
            ref: inputElement => {
                inputElement?.focus();
                setTimeout(() => inputElement?.focus(), 200);
            }, type: 'text', value: searchKey ?? '' }),
        React.createElement("div", { className: cx(styles.gifContainer, overflowDrawer && styles.gifContainerOverflow) },
            React.createElement(Grid, { columns: 2, fetchGifs: fetchGifs, gutter: 6, hideAttribution: true, key: searchKey, noLink: true, noResultsMessage: t('giphy.noResults'), onGifClick: handleGifClick, onGifKeyPress: handleGifKeyPress, width: overflowDrawer
                    ? clientWidth - (2 * OVERFLOW_DRAWER_PADDING) - SCROLL_SIZE
                    : 320 })),
        React.createElement("div", { className: styles.logoContainer },
            React.createElement("span", null, "Powered by"),
            React.createElement("img", { alt: 'GIPHY Logo', src: 'images/GIPHY_logo.png' }))));
    return overflowDrawer ? (React.createElement(JitsiPortal, null,
        React.createElement(Drawer, { className: styles.drawer, isOpen: true, onClose: onDrawerClose }, gifMenu))) : gifMenu;
}
export default GifsMenu;
